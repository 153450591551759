import { Component } from '@angular/core';
import { SLIDE_CONFIG } from 'src/app/core/utils/const/slideConfig.const';
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-retina-opiniones',
  templateUrl: './retina-opiniones.component.html',
  styleUrls: ['./retina-opiniones.component.css']
})
export class RetinaOpinionesComponent {
  public readonly slideConfig: object
  public cdn: string;
  constructor() {
   this.slideConfig = SLIDE_CONFIG.OpinionesRetinaComponent
   this.cdn = environment.cdn;
  }
}
