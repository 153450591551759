import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {AbstractService} from "./abstract.service";

@Injectable({
  providedIn: 'root'
})

export class SalesforceService extends AbstractService {

  constructor(private http: HttpClient) {
    super('salesforce');
  }

  public sendForm(data: any) {
    const body = { data: data };
    return this.http.post(`${this.baseUrl}/record/`, body, this.httpOptions);
  }

  public sendNewsletter(data: any) {
    const body = { data: data };
    return this.http.post(`${this.baseUrl}/newsletter/`, body, this.httpOptions);
  }
}
