<div gsAppear class="item__contenido-medico ">
    <img ngSrc="{{cdn}}imagenes/medicos/img-fernando.png" alt="" width="102" height="102" loading="lazy">
    <div>
    <p class="item__contenido-medico-autor">Este contenido ha sido elaborado por el <strong>Dr. Fernando Llovet</strong></p>
    <p><strong>Oftalmólogo. Co-fundador de Clínica Baviera. Miembro de la Dirección Médica. Cirujano de la Unidad de Cirugía Refractiva, Cataratas y Presbicia.</strong></p>
    <br class="visible-lg">
    <p>El Dr. Llovet cuenta con una amplia experiencia en procedimientos de cirugía refractiva láser (Lasik, Lasek y PRK) y cirugía intraocular, incluyendo cataratas e intercambio refractivo de cristalino (con lentes monofocales, multifocales y tóricas). Además, ha publicado más de 50 artículos científicos y dos libros. Ejerce como docente y es vocal de la Junta Directiva de la Sociedad Española de Cirugía Ocular Implanto-Refractiva (SECOIR).</p>
    <a href="clinica-oftalmologica-medico/fernando-llovet" class="item__contenido-medico-cv">Ver CV</a>
    </div>
</div>
