import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import {AbstractService} from "./abstract.service";

@Injectable({
  providedIn: 'root'
})


export class sendgridService extends AbstractService {
  constructor(private http: HttpClient) {
    super('sendgrid')
  }

  public sendEmail(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/send`, data)
  }

}
