<form [formGroup]="form" (ngSubmit)="onSubmit()" class="item__contact-form bg-white u-padding-top-lg u-padding-bottom-lg u-padding-left-md u-padding-right-md u-margin-top-md">
  <div [hidden]="!visible" class="form-thanks">
    <p class="big text-left">
      Hemos recibido tu solicitud. En breve nos pondremos en contacto contigo.
    </p>
    <p class="big text-left">
      <span>Gracias</span>
      <br/>
      <small>por confiar en Clínica Baviera.</small>
    </p>
  </div>
  <div [hidden]="visible">
    <div class="item__column items__padded-xs">
      <div class="item__column-2">
        <input type="text" required="true" formControlName="nombre" placeholder="Nombre y apellidos / Name and Lastname*" style="padding: 0 17px 0 30px">
        <small *ngIf="isNotValid('nombre')">
          Rellene el nombre.
        </small>
      </div>

      <!-- end item__column-2 -->
      <div class="item__column-2">
        <input type="tel" required="true" (keyup)="onlyNumbers($event)" formControlName="telefono" maxlength="9" placeholder="Teléfono / Phone*">
        <small *ngIf="isNotValid('telefono')">
          Rellene el telefono.
      </small>
      </div>

      <!-- end item__column-2 -->
      <div class="item__column-2">
        <input type="email" required="true" formControlName="email" placeholder="Email*">
        <small *ngIf="isNotValid('email')">
          Rellene el email.
      </small>
      </div>

      <!-- end item__column-2 -->
      <div class="item__column-2">
        <select required="true" formControlName="clinica" tabindex="7" class="cajaselect">
          <option value="" selected>Clínica solicitada / Clinic Requested*</option>
          <option value="Alicante">Alicante</option>
          <option value="Castellón">Castellón</option>
          <option value="Madrid">Madrid</option>
          <option value="Barcelona">Barcelona</option>
          <option value="Valencia">Valencia</option>
          <option value="Palma de Mallorca">Palma de Mallorca</option>
          <option value="Sevilla">Sevilla</option>
          <option value="Málaga">Málaga</option>
        </select>
        <small *ngIf="isNotValid('clinica')">
          Selecciona la clinica.
      </small>
      </div>

      <!-- end item__column-2 -->
      <div class="item__column-2">
        <input type="text" required="true" formControlName="ciudad" placeholder="Ciudad de origen / Hometown*">
        <small *ngIf="isNotValid('ciudad')">
          Rellena la ciudad.
        </small>
      </div>

      <!-- end item__column-2 -->
      <div class="item__column-2">
        <input type="text" required="true" formControlName="nacionalidad" placeholder="País / Country*">
        <small *ngIf="isNotValid('nacionalidad')">
          Rellena el pais.
        </small>
      </div>

      <!-- end item__column-2 -->
      <div class="item__column-1">
        <textarea formControlName="observaciones" cols="30" rows="10" placeholder="Observaciones / Message*"></textarea>
        <small *ngIf="isNotValid('observaciones')">
          Rellene las observaciones.
      </small>
      </div>

      <!-- end item__column-1 -->
      <div class="item__column-1">
        <div class="item__form-check item-black">
          <div>
            <input formControlName="acepto" type="checkbox" id="check-03">
            <label for="check-03">
              <span class="label-icon"></span>
            </label>

            <small *ngIf="isNotValid('acepto')">
              Acepta la política de privacidad.
            </small>
          </div>

          <span class="item__form-check-label">He le&iacute;do y acepto la 
            <a href="condiciones" target="_blank" class="u-underline">Pol&iacute;tica de Privacidad</a>
            / I have read and acept the <a href="condiciones" target="_blank" class="u-underline"><u>terms</u></a>
          </span>


          <!--checkbox-->
        </div>
        <div class="item__form-check item-black">

          <div>
            <input formControlName="publicidad" type="checkbox" id="check-03">
            <label for="check-03"><span class="label-icon"></span> </label>
          </div>

          <span class="item__form-check-label">
            Acepto recibir información comercial / I agree to receive commercial information
          </span>

          <!--checkbox-->
        </div>

        <button type="submit"  class="btn-orange">
          Enviar
        </button>
      </div>
    </div>
    <!-- end item__column -->
  </div>  
</form>
