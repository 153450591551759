import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";


@Injectable({
  providedIn: 'root'
})

export class GoogleMapsService {
  private isScriptLoadedSubject = new BehaviorSubject<boolean>(false);
  isScriptLoaded$ = this.isScriptLoadedSubject.asObservable();

  loadScript(): Promise<void> {
    return new Promise((resolve, reject) =>  {
      if (this.isScriptLoadedSubject.value) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      //script.src = 'https://maps.google.com/maps/api/js?key=AIzaSyAvJsdgTKoDP2vzxf3QYWmouba-Whs_bSA';
      script.src = 'https://maps.google.com/maps/api/js?key=AIzaSyDeT6kKItMdXJUwSdE38gGiPcOrjbg209Y';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.isScriptLoadedSubject.next(true);
        resolve();
      };
      script.onerror = () => {
        reject('Error loading Google Maps script');
      };
      document.head.appendChild(script);
    });
  }
}
