import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IProvince} from "../interfaces/province.interface";
import {AbstractService} from "./abstract.service";
import {ProvinceRequest} from "../models/provinceRequestModel";

@Injectable({
  providedIn: 'root'
})

export class ProvinceService extends AbstractService{

  constructor(private http: HttpClient) {
    super('provincia');
    this.paramNames = ['clinics', 'onlySliders', 'orderBy', 'sort', 'idTratamientos', 'clinicaIER']
  }

  public getProvinces(request: ProvinceRequest): Observable<IProvince[]> {
    const httpParams = this.buildParams(request);
    this.httpOptions = this.buildOptions(httpParams);
    return this.http.get<IProvince[]>(this.baseUrl, this.httpOptions)
  }

  public getProvince(id: string, request: ProvinceRequest): Observable<IProvince> {
    const httpParams = this.buildParams(request);
    this.httpOptions = this.buildOptions(httpParams);

    return this.http.get<IProvince>(`${this.baseUrl}/${id}`, this.httpOptions)
  }

  public getProvinceByAreaCode(request: ProvinceRequest, areaCode: string): Observable<IProvince> {
    const httpParams = this.buildParams(request);
    this.httpOptions = this.buildOptions(httpParams);
    return this.http.get<IProvince>(this.baseUrl.concat(`/areaCode/${areaCode}`), this.httpOptions)
  }

  public getProvinceBySeoName(seoName: string, request: ProvinceRequest): Observable<IProvince> {
    const httpParams = this.buildParams(request);
    this.httpOptions = this.buildOptions(httpParams);
    return this.http.get<IProvince>(`${this.baseUrl}/seoName/${seoName}`, this.httpOptions)
  }

}
