import {Directive, ElementRef, Inject, OnInit, PLATFORM_ID} from "@angular/core";
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {isPlatformBrowser} from "@angular/common";

@Directive({
  standalone: true,
  selector: '[gsAppear]'
})
export class GsAppearDirective implements OnInit {
  constructor(private element: ElementRef, @Inject(PLATFORM_ID) private platformID: Object) {
  }

  private hide() {
    gsap.set(this.element.nativeElement, {autoAlpha: 0});
  }

  animateFrom(elem: ElementRef, direction = 1) {
    let x = 0,
      y = direction * 200;
    if (elem.nativeElement.classList.contains("gs_reveal_fromLeft")) {
      x = -200;
      y = 0;
    } else if (elem.nativeElement.classList.contains("gs_reveal_fromRight")) {
      x = 200;
      y = 0;
    }
    elem.nativeElement.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.nativeElement.style.opacity = "0";
    gsap.fromTo(elem.nativeElement, {x: x, y: y, autoAlpha: 0}, {
      duration: 1.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto"
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      gsap.registerPlugin(ScrollTrigger);
      this.hide();
      gsap.to(this.element.nativeElement, {
        scrollTrigger: {
          trigger: this.element.nativeElement,
          //  markers: true,
          onEnter: (self: any) => {
            this.animateFrom(this.element)
          },
          // onEnterBack: () => {
          //   this.animateFrom(this.element, -1)
          // },
          // onLeave: () => {
          //   this.hide()
          // }
          // assure that the element is hidden when scrolled into view
        },
      });
    }

  }
}
