import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HeaderComponent} from "./layouts/header/header.component";
import {CommonModule} from "@angular/common";
import {FooterComponent} from "./layouts/footer/footer.component";
import {SimpleLayoutComponent} from "./layouts/simple-layout/simple-layout.component";
import {ErrorInterceptor} from "./core/interceptors/error.interceptor";


@NgModule({
  declarations: [
    HeaderComponent,
    AppComponent,
    FooterComponent,
    SimpleLayoutComponent
  ],
  imports: [
    CoreModule,
    CommonModule,
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
