import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {AbstractService} from "./abstract.service";
import { ICalculadora } from "../interfaces/calculadora.interface";

@Injectable({
  providedIn: 'root'
})

export class CalculadoraService extends AbstractService {

  constructor(private http: HttpClient) {
    super('calculadora');
    this.paramNames = ['calculadora']
  }

  public getCalculadora(id: number): Observable<ICalculadora> {
    return this.http.get<ICalculadora>(`${this.baseUrl}/${id}`)
  }

  public getCalculadoras(): Observable<ICalculadora[]> {
    return this.http.get<ICalculadora[]>(this.baseUrl, this.httpOptions)
  }
}
