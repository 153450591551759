<header>

<div class="item__top-bar">
  <div class="wrapper">
    <a routerLink="./">
      <picture>
        <!-- <source srcset="" media="(min-width: 768px)" type="image/webp" class="img-responsive center-block" width="260" height="394"> -->
        <source
          srcset="{{cdn}}imagenes/logo-clinica-baviera-desktop.png"
          media="(min-width: 768px)"
          type="image/jpeg">
        <!-- <source srcset="#>" type="image/webp" class="img-responsive center-block" width="375" height="560"> -->
        <img src="{{cdn}}imagenes/logo-clinica-baviera.png" alt="Clinica Baviera" >
      </picture>
    </a>
    <div class="item__top-bar-icons-mobile">
      <a #btnAreaPatientMobile (click)="TogglePatientArea($event, btnAreaPatientMobile)" class="button-patient-area"><img src="{{cdn}}imagenes/icons/icon-user-white.svg" alt=""></a>
      <a #menuOpen (click)="openMenu($event, menuOpen)" class="open-menu">
        <img src="{{cdn}}imagenes/icons/icon-hamburguer-white.svg" alt="">
      </a>
    </div>
    <!-- end icon-mobile -->
    <div class="item__top-bar-btns-desktop">
      <a #btnAreaPatient (click)="TogglePatientArea($event, btnAreaPatient)"  class="btn-header-area button-patient-area" style="cursor:pointer">
        <img src="{{cdn}}imagenes/icons/icon-user-circle.svg" alt="" width="48" height="49">
        Área Paciente
      </a>
      <a href="https://www.clinicabaviera.com/pide-cita/?idCampanya=web" class="btn-header-cita">
        <img src="{{cdn}}imagenes/icons/icon-calendar-circle.svg" alt="" width="48" height="49">
        Cita Online
      </a>
    </div>
    <!-- end btns-desktop -->


  </div>
  <!-- end wrapper -->
</div>
<!-- end item__top-bar -->



<!-- desplegable area paciente -->

<div class="box-parient-area-menu">
  <div class="patient-area-menu ">
    <div class="menu-container">
      <div class="wrapper">
        <div class="form-container">
          <div class="block-form">
            <div class="form-content">
              <p class="form-title">
                <span>Accede a tu</span>
                <span>Área Paciente</span>
              </p>

              <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form-login-areapaciente">
                <label class="screen-hidden" for="nif-nie">NIF/NIE</label>
                <input id="nif-nie" type="text" name="User" formControlName="User" placeholder="NIF/NIE">

                <small *ngIf="isNotValid('User')">
                  NIF/NIE incorrecto.
                </small>

                <input id="password-areapaciente" type="password" name="Password" formControlName="Password" placeholder="Contraseña">
                <small *ngIf="isNotValid('Password')">
                  Rellene el password.
                </small>

                <button id="login-areapaciente" type="submit">Acceder</button>

                <a class="forgot-password text-center" href="https://areapaciente.clinicabaviera.com/intro/recuperarpassword" title="¿Has olvidado tu contraseña?">
                  ¿Has olvidado tu contraseña?</a>
              </form>
            </div>

            <div class="box-no-cuenta">
              <div class="no-cuenta"><a  #btnNoCuenta (click)="ToggleNoCuenta($event, btnNoCuenta)"   class="btn_no-cuenta">¿No tienes cuenta?</a></div>
              <div class="desplegable">
                <p>Si aún no tienes cuenta ponte<br> en contacto con tu clínica </p>
              </div>
            </div>
          </div>

        </div>

        <div class="icons-container">
          <h4>¿Qué puedes hacer en tu Área Paciente?</h4>
          <ul>
            <li class="icon-card reports">
              <p>
                <span>Consulta tus </span>
                <span>informes médicos</span>
                <span>y justificantes</span>
              </p>
            </li>
            <li class="icon-card videocall">
              <p>Contacta con un médico</p>
            </li>
            <li class="icon-card calendar">
              <p>Accede a tu calendario de citas</p>
            </li>
            <li class="icon-card bills">
              <p>Descarga tus presupuestos y facturas</p>
            </li>
          </ul>

          <div class="link-web">

            <a href="https://www.clinicabaviera.com/areapaciente/">Quiero saber más sobre el Área Paciente</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div><!-- box-parient-area-menu -->
<div class="overlay"></div>






<nav class="item__mobile-menu">
  <div class="item__mobile-menu-heading">
    <img src="{{cdn}}imagenes/logo-clinica-baviera-blue.png" alt="">
    <div>
      <a #btnAreaPatient (click)="TogglePatientArea($event, btnAreaPatient)" class=""><img src="{{cdn}}imagenes/icons/icon-user-blue.svg" alt=""></a>

      <a #menuClose (click)="closeMenu($event, menuClose)" class="close-menu">
        <img src="{{cdn}}imagenes/icons/icon-close-blue.svg" alt="">
      </a>
    </div>

  </div>

  <ul class="item__mobile-menu-nav">

    <li class="">

      <a  class="menu-toggle" #toogleMenu1 (click)="toggleMenu($event,toogleMenu1)">
        Cirugía Refractiva
        <img ngSrc="{{cdn}}imagenes/icons/icon-arrow-right-blue.svg" alt="" height="20" width="12">
      </a>

      <ul #dropdown class="dropdown-menu">
        <li class="">
          <a (click)="toggleMenuInterno($event)" routerLink="cirugia-refractiva"><span>Cirugía Refractiva</span></a></li>
        <li class="dropdown-sub-menu ">
          <span>¿Qué es?</span>
          <ul class="dropdown-menu-2">
            <li class=""><a href="refractiva-miopia">Miopía</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="refractiva-hipermetropia">Hipermetropía</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="refractiva-astigmatismo">Astigmatismo</a></li>
          </ul>
        </li>
        <li class="dropdown-sub-menu ">
          <a routerLink="tratamiento-miopia-hipermetropia-astigmatismo" class=""><span>El tratamiento</span></a>
          <ul class="dropdown-menu-2">
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="lasik">Lasik</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="lasek">PRK/ Lasek</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="cirugia-refractiva-altas-graduaciones">Lente intraocular</a></li>
          </ul>
        </li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="precio-cirugia-refractiva"><span>Precio y financiación</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="cirugia-refractiva-primera-consulta"><span>Primera consulta</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="cirugia-laser-resultados"><span>Testimonios</span></a></li>
        <!-- <li class=""><a (click)="toggleMenuInterno($event)" routerLink="cirugia-refractiva-altas-graduaciones"><span>Lente intraocular</span></a></li> -->
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="refractiva-medicos"><span>Nuestros Médicos</span></a></li>
      </ul>
    </li>

    <li class="">
      <a class="menu-toggle" #toogleMenu2 (click)="toggleMenu($event,toogleMenu2)">Presbicia <img src="{{cdn}}imagenes/icons/icon-arrow-right-blue.svg" alt=""></a>

      <ul class="dropdown-menu">
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="operacion-presbicia"><span>Presbicia</span></a></li>
        <!-- <li class=""><a routerLink="operacion-presbicia"><span>¿Qué es?</span></a></li> -->
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="tratamiento-presbicia"><span>El tratamiento</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="precio-financiacion-operacion-presbicia"><span>Precio y financiación</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="operacion-presbicia-primera-consulta"><span>Primera consulta</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="cansada-resultados"><span>Testimonios</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="presbicia-medicos"><span>Nuestros Médicos</span></a></li>
      </ul>
    </li>

    <li class="">
      <a class="menu-toggle" #toogleMenu3 (click)="toggleMenu($event,toogleMenu3)">Cataratas <img src="{{cdn}}imagenes/icons/icon-arrow-right-blue.svg" alt=""></a>
      <ul class="dropdown-menu">
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="operacion-cataratas"><span>Cataratas</span></a></li>
        <!-- <li class=""><a routerLink="operacion-cataratas"><span>¿Qué son?</span></a></li> -->
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="tratamiento-cataratas"><span>El tratamiento</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="precio-financiacion-operacion-cataratas"><span>Precio y financiación</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="primera-consulta-cataratas"><span>Primera consulta</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="resultados-cirugia-cataratas"><span>Testimonios</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="cataratas-medicos"><span>Nuestros Médicos</span></a></li>
      </ul>
    </li>

    <li class="">
      <a class="menu-toggle" #toogleMenu4 (click)="toggleMenu($event,toogleMenu4)">Retina <img src="{{cdn}}imagenes/icons/icon-arrow-right-blue.svg" alt=""></a>
      <ul class="dropdown-menu">
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="unidad-de-retina"><span>Retina</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="que-es-la-unidad-de-retina"><span>¿Qué es?</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="oftalmologos-unidad-de-retina"><span>Nuestros Médicos</span></a></li>
        <li class="dropdown-sub-menu ">
          <a routerLink="unidad-de-retina" class=""><span>Patologías</span></a>
          <ul class="dropdown-menu-2">
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="agujero-macular">Agujero Macular</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="coroiditis-central-serosa">Coroiditis Central Cerosa</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="desprendimiento-retina">Desprendimiento de Retina</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="degeneracion-macular-asociada-edad">Degeneración Macular Asociada a la Edad o DMAE</a>
            </li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="membrana-epirretiniana">Membrana Epirretiniana</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="miopia-magna">Miopía Magna</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="retinopatia-diabetica">Retinopatía Diabética</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="oclusiones-venosas-retinas">Oclusiones Venosas de la Retina</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="desprendimiento-posterior-vitreo">Desprendimiento posterior Vítreo</a></li>
            <li class=""><a (click)="toggleMenuInterno($event)" routerLink="retinosis-pigmentaria">Retinosis Pigmentaria</a></li>
          </ul>
        </li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="clinica-oftalmologica-retina"><span>Clínicas</span></a></li>
      </ul>
    </li>

    <li class="">
      <a class="menu-toggle" #toogleMenu5 (click)="toggleMenu($event,toogleMenu5)">Otros Tratamientos <img src="{{cdn}}imagenes/icons/icon-arrow-right-blue.svg" alt=""></a>
      <ul class="dropdown-menu">
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="otros-tratamientos"><span>Otros Tratamientos</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="otros_tratamientos_oculoplastia"><span>Oculoplastia</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="ojo-seco"><span>Ojo seco</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="cornea-queratocono"><span>Enfermedades de la Córnea</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="estrabismo"><span>Estrabismo</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="oftalmologia-pediatrica"><span>Oftalmología pediátrica</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="glaucoma"><span>Glaucoma</span></a></li>
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="alteraciones-neuroftalmologicas"><span>Alteraciones Neuroftalmologicas</span></a></li>        
        <li class=""><a (click)="toggleMenuInterno($event)" routerLink="otros-tratamientos-medicos"><span>Nuestros Médicos</span></a></li>
      </ul>
    </li>

    <li class=""><a (click)="toggleMenuInterno($event)" routerLink="nuestros-medicos">Nuestros Médicos</a></li>
    <li class=""><a (click)="toggleMenuInterno($event)" routerLink="donde-estamos">Nuestras clínicas</a></li>
    <li class=""><a (click)="toggleMenuInterno($event)" routerLink="empresa-responsable">Baviera Responsable</a></li>
  </ul>
  <div>
  <img src="{{cdn}}imagenes/promo/promo-mobile-consulta.png" alt="">
  <p class="item__menu-nota">Consulta las <a routerLink="promociones"><strong>condiciones de la promoción</strong></a></p>
</div> 
</nav>
<nav class="item__menu-desktop">
  <ul class="item__menu-desktop-nav">
    <!-- <li (mouseenter)="onMouseEnter()"
      (mouseleave)="onMouseOut()" ng-mouseover=""
      > -->
    <li>
      <a
      routerLink="cirugia-refractiva"
      routerLinkActive="active"
      > Cirugía Refractiva
        <img src="{{cdn}}imagenes/icons/icon-arrow-down-blue.svg" alt="">
      </a>

      <nav class="item__menu-desktop-submenu-hover" >
        <ul class="item__menu-desktop-submenu-hover-nav">
          <li class=" item__menu-father">
            <a 
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:true}" style="cursor: pointer" >¿Qué es?<img src="{{cdn}}imagenes/icons/icon-arrow-down-black.svg" alt=""></a>
            <ul class="submenu-child " dropdownMenuDesktop>
              <li class=""><a routerLink="refractiva-miopia">Miopía</a></li>
              <li class=""><a routerLink="refractiva-hipermetropia">Hipermetropía</a></li>
              <li class=""><a routerLink="refractiva-astigmatismo">Astigmatismo</a></li>
            </ul>
          </li>

          <li class="item__menu-father">
            <a routerLink="tratamiento-miopia-hipermetropia-astigmatismo"
               routerLinkActive="active"
               [routerLinkActiveOptions]="{exact:true}"
               class="">
              El tratamiento <img src="{{cdn}}imagenes/icons/icon-arrow-down-black.svg" alt=""></a>
            <ul class="submenu-child " dropdownMenuDesktop>
              <li class=""><a routerLink="lasik">Lasik</a></li>
              <li class=""><a routerLink="lasek">PRK/ Lasek</a></li>
              <li class=""><a routerLink="cirugia-refractiva-altas-graduaciones">Lente intraocular</a></li>
            </ul>
          </li>
          <li class=""><a routerLink="precio-cirugia-refractiva"><span>Precio y financiación</span></a></li>
          <li class=""><a routerLink="cirugia-refractiva-primera-consulta"><span>Primera consulta</span></a></li>
          <li class=""><a routerLink="cirugia-laser-resultados"><span>Testimonios</span></a></li>
          <!-- <li class=""><a routerLink="cirugia-refractiva-altas-graduaciones"><span>Lente intraocular</span></a></li> -->
          <li class=""><a routerLink="refractiva-medicos"><span>Nuestros Médicos</span></a></li>
        </ul>

      </nav>
      <!-- end item__menu-desktop-hover -->
    </li>
    <li class="">
      <a routerLink="operacion-presbicia" routerLinkActive="active" >Presbicia<img src="{{cdn}}imagenes/icons/icon-arrow-down-blue.svg" alt=""></a>
      <nav class="item__menu-desktop-submenu-hover">
        <ul class="item__menu-desktop-submenu-hover-nav ">
          <!-- <li class=""><a routerLink="operacion-presbicia" routerLinkActive="active">¿Qué es?</a></li> -->
          <li class=""><a routerLink="tratamiento-presbicia">El tratamiento</a></li>
          <li class=""><a routerLink="precio-financiacion-operacion-presbicia">Precio y financiación</a></li>
          <li class=""><a routerLink="operacion-presbicia-primera-consulta">Primera consulta</a></li>
          <li class=""><a routerLink="cansada-resultados">Testimonios</a></li>
          <li class=""><a routerLink="presbicia-medicos">Nuestros Médicos</a></li>
        </ul>
      </nav>

    </li>
    <li class="">
      <a routerLink="operacion-cataratas" routerLinkActive="active" >Cataratas<img src="{{cdn}}imagenes/icons/icon-arrow-down-blue.svg" alt=""></a>
      <nav class="item__menu-desktop-submenu-hover">
        <ul class="item__menu-desktop-submenu-hover-nav ">
          <!-- <li class=""><a routerLink="operacion-cataratas" rel="nofollow">¿Qué son?</a></li> -->
          <li class=""><a routerLink="tratamiento-cataratas">El tratamiento</a></li>
          <li class=""><a routerLink="precio-financiacion-operacion-cataratas">Precio y financiación</a></li>
          <li class=""><a routerLink="primera-consulta-cataratas">Primera consulta</a></li>
          <li class=""><a routerLink="resultados-cirugia-cataratas">Testimonios</a></li>
          <li class=""><a routerLink="cataratas-medicos">Nuestros Médicos</a></li>
        </ul>
      </nav>
    </li>
    <li class="">
      <a routerLink="unidad-de-retina" routerLinkActive="active">Retina<img src="{{cdn}}imagenes/icons/icon-arrow-down-blue.svg" alt=""></a>
      <nav class="item__menu-desktop-submenu-hover">
        <ul class="item__menu-desktop-submenu-hover-nav">
          <li class=""><a routerLink="que-es-la-unidad-de-retina">¿Qué es?</a></li>
          <li class=""><a routerLink="oftalmologos-unidad-de-retina">Nuestros médicos</a></li>
          <li class="item__menu-father ">
            <a routerLink="unidad-de-retina"
                 routerLinkActive="active"
                 [routerLinkActiveOptions]="{exact:true}"
                 class="">
              Patologías
              <img src="{{cdn}}imagenes/icons/icon-arrow-down-black.svg" alt="">

            </a>
            <ul class="submenu-child">
              <li class=""><a routerLink="agujero-macular">Agujero Macular</a></li>
              <li class=""><a routerLink="coroiditis-central-serosa">Coroidopatía  Serosa Central</a></li>
              <li class=""><a routerLink="desprendimiento-retina">Desprendimiento de Retina</a></li>
              <li class=""><a routerLink="degeneracion-macular-asociada-edad">Degeneración Macular Asociada a la Edad o DMAE</a></li>
              <li class=""><a routerLink="membrana-epirretiniana">Membrana Epirretiniana</a></li>
              <li class=""><a routerLink="miopia-magna">Miopía Magna</a></li>
              <li class=""><a routerLink="retinopatia-diabetica">Retinopatía Diabética</a></li>
              <li class=""><a routerLink="oclusiones-venosas-retinas">Oclusiones Venosas de la Retina</a></li>
              <li class=""><a routerLink="desprendimiento-posterior-vitreo">Desprendimiento posterior Vítreo</a></li>
              <li class=""><a routerLink="retinosis-pigmentaria">Retinosis Pigmentaria</a></li>
            </ul>
          </li>
          <li class=""><a routerLink="clinica-oftalmologica-retina">Clínicas</a></li>
        </ul>
      </nav>
    </li>
    <li class="">
      <a routerLink="otros-tratamientos" routerLinkActive="active" >Otros Tratamientos<img src="{{cdn}}imagenes/icons/icon-arrow-down-blue.svg" alt=""></a>
      <nav class="item__menu-desktop-submenu-hover">
        <ul class="item__menu-desktop-submenu-hover-nav ">
          <li class=""><a routerLink="otros-tratamientos-oculoplastia">Oculoplastia</a></li>
          <li class=""><a routerLink="ojo-seco">Ojo seco</a></li>
          <li class=""><a routerLink="cornea-queratocono">Enfermedades<br>de la córnea</a></li>
          <li class=""><a routerLink="estrabismo">Estrabismo</a></li>
          <li class=""><a routerLink="oftalmologia-pediatrica">Oftalmología<br>Pediátrica</a></li>
          <li class=""><a routerLink="glaucoma">Glaucoma</a></li>


          <li class=""><a routerLink="alteraciones-neuroftalmologicas">Alteraciones<br>Neuroftalmológicas</a></li>

          <li class=""><a routerLink="otros-tratamientos-medicos">Nuestros  Médicos</a></li>
        </ul>
      </nav>

    </li>
    <li class=""><a routerLink="nuestros-medicos" routerLinkActive="active" >Nuestros Médicos</a></li>
    <li class=""><a routerLink="donde-estamos" routerLinkActive="active" >Nuestras clínicas</a></li>
    <li class=""><a routerLink="empresa-responsable" routerLinkActive="active" >Baviera Responsable</a></li>
  </ul>

</nav>

</header>


