<section class="opiniones bg-opiniones ">
    <div class="wrapper">
      <h2 class="opiniones__title">Conoce las opiniones reales <strong>de nuestros pacientes</strong></h2>
  
  
      <div class="opiniones__box-puntuacion">
        <div class="opiniones__box-logo">
          <img src="{{cdn}}imagenes/icons/icon-award.svg" alt="">
          <img src="{{cdn}}imagenes/logo-google.svg" alt="">
        </div>
        <div class="opiniones__box-resultado">
          <div class="opiniones__stars">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
          </div>
          <div class="opiniones__puntuacion">
            <p><strong>4,8</strong>/5</p>
  
          </div>
        </div> <!-- end opiniones__box-resultado -->
  
  
      </div><!-- end opiniones__box-puntuacion -->
  
      <div class="opiniones__items">
        <div class="opiniones__items-slider">
          <ngx-slick-carousel [config]="slideConfigOpiniones">
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Jorge Medina Ortiz
                  </p>
                  <p class="opiniones__items-text-date">Agosto de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Muy contento con el resultado de la operación y posteriores revisiones. Me lo recomendaron y sin duda ahora lo recomendaré yo.  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Susana Fayos
                  </p>
                  <p class="opiniones__items-text-date">Agosto de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Excelente trato y profesionalidad. Operación láser rápida, indolora y eficiente!
                </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Jose Ibañez
                  </p>
                  <p class="opiniones__items-text-date">Agosto de 2023</p>
                  <p class="opiniones__items-text-quote">
                    El trato de lujo,la operación ni me enteré y el resultado espectacular, muchísimas gracias
                </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    María Galiana Llorca
                  </p>
                  <p class="opiniones__items-text-date">Agosto de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Mi experiencia en Clínica Baviera ha sido muy buena. Me traté de miopía con la técnica de láser y los resultados han sido excelentes. El Dr. De Casas y el resto del personal me han tratado con amabilidad y me han explicado detalladamente la técnica de la cirugía, la cual fue muy rápida y efectiva.
                </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Manuel Amador Rodríguez Salgueiro
                  </p>
                  <p class="opiniones__items-text-date">Agosto de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Excelentes profesionales tanto en el trato como en el servicio, me he operado de miopía y astigmatismo y desde el momento que he llamado para pedir vez para la consulta todo fueron facilidades, recomendable al 100%, gracias a todo el equipo
                </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Marijo León
                  </p>
                  <p class="opiniones__items-text-date">Agosto de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Estoy operada desde el 2013 de astigmatismo y es lo mejor que he hecho en mi vida, no uso, gafas ni lentillas, solo gafas de sol, ánimo a la gente que se opere.                </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Jose Rg
                  </p>
                  <p class="opiniones__items-text-date">Agosto de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Tanto doctores como personal sanitario  son un 100! Explican detalladamente todo desde el principio hasta el final de la visita, transmiten muchísima confianza y los resultados son buenísimos. Muy contento con la cirugia y el trato recibido. Sin duda, lo recomendaré  </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    María Jesus Melgarejo
                  </p>
                  <p class="opiniones__items-text-date">Julio de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Muy contenta con el trato y la profesionalidad. Me operé de miopía y astigmatismo de casi seis dioptrías en cada ojo, el resultado ha sido excelente. Si me tuviera que volver a operar sin duda sería en Clínica Baviera. 100% recomendable
                  </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Monik Bolaños
                  </p>
                  <p class="opiniones__items-text-date">Julio de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Muy contenta, se operó mi marido y yo, es lo mejor que nos ha pasado. Lo recomiendo 100% muy profesionales.
                  </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Jordi
                  </p>
                  <p class="opiniones__items-text-date">Julio de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Me operé de los dos ojos con láser y estoy encantado, super recomendable!!
                  </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Montserrat Garrober
                  </p>
                  <p class="opiniones__items-text-date">Junio de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Muy contenta con la operación mediante el láser PRK. ¡Ya hace 3 meses y veo muy bien!
                  </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    María
                  </p>
                  <p class="opiniones__items-text-date">Junio de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Buena atención, te explican todo el proceso de forma clara y además siempre están disponibles si tienes alguna duda. Sin duda operarme con ellos ha sido un gran acierto. Muy recomendable.
                  </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Adriana Azagra Antin
                  </p>
                  <p class="opiniones__items-text-date">Junio de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Una maravilla abrir los ojos por la mañana y no tener que buscar las gafas. </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Natalia Ibañez
                  </p>
                  <p class="opiniones__items-text-date">Junio de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Una clínica de confianza, y unos gran profesionales tanto por su trato como su trabajo, 100% recomendada.<br>

                    Me operé hace dos meses de miopía y astigmatismo y estoy encantada.
                  </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Jose Alberto Ibañez San Roman
                  </p>
                  <p class="opiniones__items-text-date">Mayo de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Recomendable 100x100 en todo desde el trato q me han dado hasta los resultados un cambio en mi vida
                  </div>
              </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Ania Hernani
                  </p>
                  <p class="opiniones__items-text-date">Marzo de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Me operé allí el año pasado, con más de 10 dioptrías en cada ojo, y estoy muy contenta.<br>
                    Los trabajadores siempre muy amables. Merece la pena operarse allí.
                  </div>
              </div><!--  end opiniones__items-card -->
            </div>


            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Enrique Soto
                  </p>
                  <p class="opiniones__items-text-date">Enero de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Me operé hace tres meses y ha sido tanto el trato como el seguimiento fantástico. Tenía unas 4 - 5 dioptrias en cada ojo y ahora, no sólo veo perfectamente, sino el 120%. Profesionales al máximo, no pierdo oportunidad para recomendaros.</p>
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Sara Álvarez
                  </p>
                  <p class="opiniones__items-text-date">Enero de 2023</p>
                  <p class="opiniones__items-text-quote">
                    Personal atento, agradable inmejorables, servicio perfecto, encantada y super agradecida tras operación láser. </p>
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    David Mellado
                  </p>
                  <p class="opiniones__items-text-date">Diciembre de 2022</p>
                  <p class="opiniones__items-text-quote">
                    Muy bien explicado, atendido y acompañado en todo el proceso. Expectativas cumplidas al 100%. Adiós gafas
                  </p>
                </div>
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Carol
                  </p>
                  <p class="opiniones__items-text-date">Diciembre de 2022</p>
                  <p class="opiniones__items-text-quote">
                    Muy contenta lo mejor que he hecho en mi vida operarme... dependía  todo el día  de las lentillas ahora veo 100%
                  </p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Iker Huarte
                  </p>
                  <p class="opiniones__items-text-date">Diciembre de 2022</p>
                  <p class="opiniones__items-text-quote">
                    Muy contenta lo mejor que he hecho en mi vida operarme... dependía  todo el día  de las lentillas ahora veo 100%
                  </p>
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Iker Huarte
                  </p>
                  <p class="opiniones__items-text-date">Diciembre de 2022</p>
                  <p class="opiniones__items-text-quote">
                    He tenido que operarme por obligación puesto que es un requisito indispensable para la oposición y la verdad que me alegro de haberlo hecho. El trato impresionante y la atención exquisita. Agradezco la profesionalidad que tienen. Muy recomendable.
                  </p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Ana Rosello Besteiro
                  </p>
                  <p class="opiniones__items-text-date">Octubre de 2022</p>
                  <p class="opiniones__items-text-quote">
                    Recomiendo 120 x 100!!! Todo estupendo, el trato, la cirugia, la recuperacion...<br>
                    GENIAL!! DE 10!! MUY CONTENTA
                  </p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Mónica Hernández
                  </p>
                  <p class="opiniones__items-text-date">Octubre de 2022</p>
                  <p class="opiniones__items-text-quote">
                    Me operé hace tres meses y ha sido tanto el trato como el seguimiento fantástico. Tenía unas 4 - 5 dioptrias en cada ojo y ahora, no sólo veo perfectamente, sino el 120%. Profesionales al máximo, no pierdo oportunidad para recomendaros.
                  </p>
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Sergio Fernández Sánchez
                  </p>
                  <p class="opiniones__items-text-date">Octubre de 2022</p>
                  <p class="opiniones__items-text-quote">
                    De la mejor atención que he recibido en una clínica. Rapidos, eficaces y profesionales. Me operé la miopía y fue un éxito, además de ser económicamente asequible.
                  </p>
  
                </div>
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Mario Merino
                  </p>
                  <p class="opiniones__items-text-date">Septiembre de 2022</p>
                  <p class="opiniones__items-text-quote">Experiencia 10/10, en cuanto decidí operarme fue todo súper rápido de una semana para otra, de agradecer el tan poco tiempo de espera y las facilidades que te dan para todo. El personal mas que excelente, repetiría sin duda.</p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Vicenta Beneito
                  </p>
                  <p class="opiniones__items-text-date">Septiembre de 2022</p>
                  <p class="opiniones__items-text-quote">
                    Fenomenal!!! Cirugía sencilla, atención inmejorable. Resultado estupendo y. El personal sanitario muy agradable.<br>
                    Ánimo a los indecisos!!!!
                  </p>
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Julen Rodríguez
                  </p>
                  <p class="opiniones__items-text-date">Septiembre de 2022</p>
                  <p class="opiniones__items-text-quote">
                    ¡Totalmente recomendado!<br>
                    Me operé de miopía y astigmatismo, y tanto la cirugía como el post operatorio fueron muy llevaderos. Muy profesionales y simpaticos.
                  </p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Marina Puelles
                  </p>
                  <p class="opiniones__items-text-date">Diciembre de 2021</p>
                  <p class="opiniones__items-text-quote">
                    Atención genial.Ahora este mes la  primera consulta es gratuita y te Dan toda la información  si puedes operarte .Te Dan facilidades para pagar.Muy recomendable
                  </p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Juan Córdoba
                  </p>
                  <p class="opiniones__items-text-date">Noviembre de 2021</p>
                  <p class="opiniones__items-text-quote">
                    Me operé de miopía y estoy muy contento por el resultado y el trato que me han dado.
                   </p>
                </div>
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Sandra Salgado Gonzalez
                  </p>
                  <p class="opiniones__items-text-date">Octubre de 2021</p>
                  <p class="opiniones__items-text-quote">
                    Lo mejor de mi vida!!! Adiós gafas!!!!
                    Super contenta con el trato , super atentas en todo momento ! La mejor decisión que he podido tomar!!!  </p>
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    D. Royo
                  </p>
                  <p class="opiniones__items-text-date">Diciembre de 2020</p>
                  <p class="opiniones__items-text-quote">
                    Muy contento. Me operé en 2008. Llevo más de 10 años operado con la técnica LASIK y sigo viendo bien sin necesidad de gafas ni lentillas
                  </p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    NR
                  </p>
                  <p class="opiniones__items-text-date">Octubre de 2020</p>
                  <p class="opiniones__items-text-quote">
                    Después de 14 años con gafas, ya me olvido de ellas por completo. ¡No puedo estar más contenta! Lo recomiendo al 100%.
                  </p>
                </div>
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Sofia Abril
                  </p>
                  <p class="opiniones__items-text-date">Septiembre de 2020</p>
                  <p class="opiniones__items-text-quote">
                    Con 7,5 dioptrías de miopía y después de la operación de lasik 0. Trato estupendo y unos profesionales.
                  </p>
                </div>
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
                  </p>
                  <p class="opiniones__items-text-nombre">
                    David Fernández
                  </p>
                  <p class="opiniones__items-text-date">Mayo de 2020</p>
                  <p class="opiniones__items-text-quote">
                    Contento con el trato y el resultado.
                  </p>
                </div>
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Patricia Cabañas Sáenz
                  </p>
                  <p class="opiniones__items-text-date">Enero de 2022</p>
                  <p class="opiniones__items-text-quote">
                    Muy profesionales, buen trato y atención, estupendas instalaciones
                  </p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Inma Sanchez
                  </p>
                  <p class="opiniones__items-text-date">Noviembre de 2021</p>
                  <p class="opiniones__items-text-quote">
                    Muchas gracias a todo el equipo de Baviera Madrid por el magnífico trato al paciente y su gran profesionalidad. Hace veinte años que me operaron de miopía y siguen siendo mis oftalmólogos de referencia. Los recomiendo 100%. Enhorabuena y muchas gracias.
                  </p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Mercedes Álvarez
                  </p>
                  <p class="opiniones__items-text-date">Octubre de 2021</p>
                  <p class="opiniones__items-text-quote">
                    Muy profesionales.
                  </p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Ana Durán
                  </p>
                  <p class="opiniones__items-text-date">Septiembre de 2021</p>
                  <p class="opiniones__items-text-quote">
                    Yo me operé hace dos semanas, desde entonces me ha cambiado la vida, soy otra persona, estoy muy feliz!!!, muchísimas gracias por vuestro trato y profesionalidad, solo puedo decir que ha sido excelente siempre.
                  </p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
            <div ngxSlickItem>
              <div class="opiniones__items-card">
                <div class="opiniones__stars">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                  <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                  <p class="opiniones__items-text-paciente">
                    PACIENTE
  
                  </p>
                  <p class="opiniones__items-text-nombre">
                    Aida María Garcia López
                  </p>
                  <p class="opiniones__items-text-date">Agosto de 2021</p>
                  <p class="opiniones__items-text-quote">
                    Buen trato y grandes profesionales. Una clínica muy recomendable
                  </p>
  
  
                </div>
  
  
              </div><!--  end opiniones__items-card -->
            </div>
  
  
          </ngx-slick-carousel>
        </div><!--  end opiniones__items-slider -->
  
      </div><!-- end opiniones__items -->
  
  
  
  
  
  
    </div><!--  end wrapper -->
  
  </section>