import { Component } from '@angular/core';
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-box-medico-gonzalo-munoz',
  templateUrl: './box-medico-gonzalo-munoz.component.html',
  styleUrls: ['./box-medico-gonzalo-munoz.component.css']
})
export class BoxMedicoGonzaloMunozComponent {
  public cdn: string;
  constructor() {
    this.cdn = environment.cdn;
   }
}
