import { Component } from '@angular/core';
import { SLIDE_CONFIG } from '../../utils/const/slideConfig.const';
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-otros-tratamientos-opiniones',
  templateUrl: './otros-tratamientos-opiniones.component.html',
  styleUrls: ['./otros-tratamientos-opiniones.component.css']
})
export class OtrosTratamientosOpinionesComponent {
  public slideConfig: object
  public cdn: string
  constructor() {
    this.slideConfig = SLIDE_CONFIG.OpinionesOtrosTratamientosComponent
    this.cdn = environment.cdn;
  }
}
