<section class="opiniones bg-opiniones ">
    <div class="wrapper">
        <h2 class="opiniones__title">Conoce las opiniones reales <strong>de nuestros pacientes</strong></h2>


        <div class="opiniones__box-puntuacion">
            <div class="opiniones__box-logo">
                <img src="{{cdn}}imagenes/icons/icon-award.svg" alt="">
                <img src="{{cdn}}imagenes/logo-google.svg" alt="">
            </div>
            <div class="opiniones__box-resultado">
                <div class="opiniones__stars">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                </div>
                <div class="opiniones__puntuacion">
                    <p><strong>4,8</strong>/5</p>

                </div>
            </div> <!-- end opiniones__box-resultado -->


         </div><!-- end opiniones__box-puntuacion -->

         <div class="opiniones__items">
            <div class="opiniones__items-slider">
                <ngx-slick-carousel [config]="slideConfig">

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Fco. Javier Navarro Ortega
                                </p>
                                <p class="opiniones__items-text-date">Febrero de 2024</p>
                                <p class="opiniones__items-text-quote">
                                    Un antes y un después. Me he operado de presbicia, y me ha cambiado la vida.</p>
    
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Pedro Mora Muñoz
                                </p>
                                <p class="opiniones__items-text-date">Enero de 2024</p>
                                <p class="opiniones__items-text-quote">
                                    Fue espectacular, de la noche al día  , increíble, parezco como si tuviera 20 años joven,fue lo mejor que hice! Y todo gracias a clínica Baviera!</p>
    
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Julio Francés Lopez
                                </p>
                                <p class="opiniones__items-text-date">Enero de 2024</p>
                                <p class="opiniones__items-text-quote">
                                    La experiencia fue muy buena. Me operé de los ojos porque tenía cataratas y presbicia. Fue todo genial. Estoy super contento. Veo genial sin gafas. Lo recomiendo a todos.</p>
    
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>
                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Paco Gutierrez
                                </p>
                                <p class="opiniones__items-text-date">Diciembre de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    ¡Adiós gafas! Ya han transcurrido 3 meses desde la operación de presbicia y veo casi con tanta claridad como cuando las llevaba...y mejorando. La operación duró menos de 15 minutos, sin ningún tipo de dolor, y al día siguiente ya noté el cambio</p>
    
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Inmaculada Blay
                                </p>
                                <p class="opiniones__items-text-date">Diciembre de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Me he operado los dos ojos , presbicia, cataratas y astigmatismo. Perfecto. Lo recomiendo 100%</p>
    
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Maria Luisa Martin Ugaldea
                                </p>
                                <p class="opiniones__items-text-date">Diciembre de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Todo perfecto  como cuando me opere de presbicia y mi calidad de vida aumento tanto que aún no me lo creo casi ,maravilloso!!!</p>
    
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Agustín Benito
                                </p>
                                <p class="opiniones__items-text-date">Noviembre  de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Una esperiencia maravillosa poder ver otra vez con tanta claridad y poder leer sin gafas  con casi 70 años, no se puede pedir más.
                                    El personal muy agradable y muy profesionales
                                    Una clínica privada donde se aprecia el buen funcionamiento.
                                    Para mi excelente.
                                    Muchas gracias.</p>
    
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Alberto López
                                </p>
                                <p class="opiniones__items-text-date">Noviembre de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Honestidad. Consulté una operación de presbicia y me aconsejaron que según mi edad y circunstancias aún no era el momento. Me operaré con ellos cuando sea el momento.</p>
    
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Fabián Velasco
                                </p>
                                <p class="opiniones__items-text-date">Octubre de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    La experiencia fue extraordinaria, la solución a un problema con el uso de gafas progresivas y correctivas; las lentes intraoculares me ha cambiado la vida. Las intervenciones quirúrgicas indoloras y con los profesionales que te atiende te da la confianza suficiente como para decir "valió la pena". Clínica Baviera 100% recomendada.</p>
    
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Tomás Domínguez
                                </p>
                                <p class="opiniones__items-text-date">Agosto de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Hace ya unos meses que me operé de presbicia, mi vida a cambiado por completo, sin duda es la mejor decisión que he tomado !!! Desde el primero momento me transmitieron un total confianza!!! El personal un 10, la amabilidad un 10 , y la doctora Escudero una gran profesional!! No tengo ninguna duda en recomendárselo a cualquiera. Siempre agradecido</p>
    
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Venancio de la Cruz Domenech
                                </p>
                                <p class="opiniones__items-text-date">Agosto de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    A mí me han operado del ojo derecho de presbicia y he quedado muy bien y aprovecho para agradecer a todo el equipo médico son muy buenos profesionales y a todo el personal de la clínica que me han tratado  muy bien. Gracias</p>
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Olga Robles
                                </p>
                                <p class="opiniones__items-text-date">Julio de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Muy buena experiencia. Mi operación de visión combinada ha sido satisfactoria y puedo hacer mi día a día sin gafas.
                                    La atención prestada por la oftalmóloga así como el resto del personal, excelente.</p>
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Enrique Jose Gomez Delgado
                                </p>
                                <p class="opiniones__items-text-date">Junio de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Operación de ambos ojos con lente trifocal, todo muy bien, profesionalidad y atención impecables.</p>
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Antonio Aznar
                                </p>
                                <p class="opiniones__items-text-date">Junio de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Fantástico. En organización y servicio.
                                    Además me he operado de hipermetropía, Presbicia y astigmatismo, y por primera vez en mi vida se que me echo el champú en la cabeza, y leo (sin gafas ni lentillas) cualquier prospecto que me echen. Y las etiquetas en los supermercados.
                                    Gracias a la clínica Baviera, y al doctor Marcos.</p>
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Isabel Luna
                                </p>
                                <p class="opiniones__items-text-date">Junio de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Muy recomendable. Rápido Adiós a las gafas de cerca y de lejos!!!
                                    Y la atención.... Buenísima!!</p>
                            </div>
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Jose Victor Pardo
                                </p>
                                <p class="opiniones__items-text-date">Junio de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Ha sido una maravilla, el trato y la atención,
Vuelvo a leer libros y ver de lejos sin gafas, que hacía años que no podía.</p>
                            </div>
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Juanma Diez
                                </p>
                                <p class="opiniones__items-text-date">Mayo de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Excelente trato recibido por parte de todos sus profesionales.
                                    Multitud de pruebas para realizar diagnóstico y resultado final bueno.
                                    Presbicia, hipermetropía y astigmatismo.</p>
                            </div>
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Nelida Benito
                                </p>
                                <p class="opiniones__items-text-date">Marzo de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Operarme la presbicia ha sido  la mejor decisión que podría haber tomado .
                                    Todo ha sido muy sencillo y cómodo gracias a los profesionales que me han atendido.
                                    Estoy felíz , encantada de haberme librado de las gafas.</p>
                            </div>
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Antonio Magadan Huerta
                                </p>
                                <p class="opiniones__items-text-date">Marzo de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Fui operado de presbicia y astigmatismo y todo muy bien , muy contento con el resultado, el personal muy atentos y amables y los doctores también, lo recomiendo</p>
                            </div>
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Antonia del Hoyo Moreno
                                </p>
                                <p class="opiniones__items-text-date">Febrero de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Me he operado en diciembre de presbicia y astigmatismo y estoy muy muy contenta. La atención de enfermeras y médicos muy bien,todos muy amables. Lo recomiendo cien por cien.</p>
                            </div>
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card low-mobile">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE </p>
                                <p class="opiniones__items-text-nombre">
                                    Maria Jose Molinero Cajo
                                </p>
                                <p class="opiniones__items-text-date">Febrero de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Recomiendo Clínica Baviera 100%
                                    Me operé hace tres meses de hipermetropía, astigmatismo y presbicia, fue todo genial, veo de maravilla, sin necesidad de usar gafas para nada.
                                    Estoy encantadísima, la mejor inversión que he podido hacer</p>
                            </div>
                         </div><!--  end opiniones__items-card -->
                    </div>

                    
                    <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE </p>
                            <p class="opiniones__items-text-nombre">
                                Anrro1705 Zalduendo
                            </p>
                            <p class="opiniones__items-text-date">Enero de 2023</p>
                            <p class="opiniones__items-text-quote">
                                Ante todo la calidad humana de su personal, maravillosos. Como Clínica he quedado muy satisfecha, hace 18 años me operé de miopía y astigmatismo y ahora de presbicia y todo genial. Gracias a todo el equipo.</p>

                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Ildefonso Navarrete
                            </p>
                            <p class="opiniones__items-text-date">Diciembre de 2022</p>
                            <p class="opiniones__items-text-quote">
                                Muy recomendable. Muy profesionales, atención al cliente estupenda. Muy contento con la operación de presbicia, hoy he estado en la revisión de los dos meses y todo perfecto. Un diez</p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                J. Ignacio
                            </p>
                            <p class="opiniones__items-text-date">Diciembre de 2022</p>
                            <p class="opiniones__items-text-quote">
                                Hola buenas en mi caso tenía hipermetropía,la presbicia y cataratas y me he operado de las tres cosas y la verdad que llevo tres meses y contentoo y el equipo son fenómenos.os lo recomiendo. Saludos
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Ferran Rivera
                            </p>
                            <p class="opiniones__items-text-date">Octubre de 2022</p>
                            <p class="opiniones__items-text-quote">Gran decisión la de operarme la plesbicia y gran elección hacerlo en Clinica Baviera.
                                Gran profesionalidad y amabilidad de la Dra. LAURA y su equipo.<br>
                                Muchas gracias</p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Andreu Roig
                            </p>
                            <p class="opiniones__items-text-date">Septiembre de 2022</p>
                            <p class="opiniones__items-text-quote">En mayo del 2022 me operé de presbicia para no tener que llevar gafas, me atendió el dr. Santaloria, y no puedo estar más agradecido, llevaba gafas desde el 2010 y de saberlo me hubiera operado antes, ahora veo las cosas de otra manera, si duda en dejar de llevar gafas no pierda más el tiempo y vaya a verlos.</p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Neus Vives
                            </p>
                            <p class="opiniones__items-text-date">Septiembre de 2022</p>
                            <p class="opiniones__items-text-quote">Me opere de la vista cansada, y cataractes .Genial la intervencion ahora veo de cerca y de lejos , estoy super contenta de no llevar gafas. Muchas gracias a todos que son un equipo estupendo. Gracias a mi cirujano Dr. Guillem Alonso.</p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Leo Viloria
                            </p>
                            <p class="opiniones__items-text-date">Agosto de 2022</p>
                            <p class="opiniones__items-text-quote">La operación de presbicia, me ha cambiado la vida ... La mejor inversión de mi vida ... El trato del personal espectacular!!!....y la Dra Escudero.... La mejor..... Trato impecable, profesionalidad y una amabilidad y delicadeza inmejorable.... Sin duda alguna gracias por mejorarme la calidad de vida.... Sois muy buenos!!!!</p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Beatriz García
                            </p>
                            <p class="opiniones__items-text-date">Febrero de 2022</p>
                            <p class="opiniones__items-text-quote">
                                Muy contenta con la operación de presbicia ,después de seis meses muy buenos resultados, no me ha gustado que al darme el alta me recomiendan revisión cada dos o tres años consulta que tendré que pagar , habiendo pagado ya lo que he pagado que no es nada barato .</p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Inma Camporro
                            </p>
                            <p class="opiniones__items-text-date">Diciembre de 2021</p>
                            <p class="opiniones__items-text-quote">
                                Encantada con el resultado, operación de presbicia en ambos ojos. Un regalo para los sentidos poder ver perfectamente sin gafas o lentillas.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Paco Lopez Dominguez
                            </p>
                            <p class="opiniones__items-text-date">Noviembre de 2021</p>
                            <p class="opiniones__items-text-quote">
                                Me he operado de presbicia hace una semana y os digo que maravilloso trato increíble y resultados magníficos la recomiendo sin dudarlo</p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Pablo Rus
                            </p>
                            <p class="opiniones__items-text-date">Octubre de 2021</p>
                            <p class="opiniones__items-text-quote">
                                Muy contento con la cirugía de presbicia,muchas gracias al doctor Navalon y a todo su equipo,por fin he dejado de utilizar las molestas gafas,un saludo<br>
                                Pablo</p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Ana Rio
                            </p>
                            <p class="opiniones__items-text-date">Septiembre de 2021</p>
                            <p class="opiniones__items-text-quote">
                                Me he operado de presbicia y estoy encantada con el resultado. El doctor Junquera es un excelente profesional que te guía durante el proceso resolviendo todas tus dudas. Una clínica 100% recomendable con un trato inmejorable por parte de todo el equipo.</p>


                     </div><!--  end opiniones__items-card -->
                </div>
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Marian Ara Aparisi
                            </p>
                            <p class="opiniones__items-text-date">Octubre de 2020</p>
                            <p class="opiniones__items-text-quote">Estoy super contenta de haber tomado la desición de operarme de cataratas y presbicia.
                                El resultado excelente y un personal muy cualificado,
                                Un 10</p>

                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card low-mobile">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                M JESUS URZAINQUI SERRANO
                            </p>
                            <p class="opiniones__items-text-date">Mayo de 2018</p>
                            <p class="opiniones__items-text-quote">
                                Me operé de presbicia y estoy super contenta. Además la doctora y el personal super amable. Un 10.</p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
            


            </ngx-slick-carousel>
            </div><!--  end opiniones__items-slider -->

          </div><!-- end opiniones__items -->






    </div><!--  end wrapper -->

</section>
