import {
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  Output, SimpleChanges,
  ViewChild
} from "@angular/core";
import * as $ from "jquery";
import {CommonModule} from "@angular/common";
import {environment} from "../../../../../environments/environment";
@Component({
  selector: 'app-modal',
  standalone: true,
  templateUrl: './modal.component.html',
  imports: [
    CommonModule
  ]
})
export class ModalComponent implements OnChanges {
  @Input() visible: boolean = false
  @Input() header: string = ''
  @Input() footer: string = ''
  @Input() infografia: string = ''
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>()
  @ViewChild('modal') modal!: ElementRef
  public cdn: string;
  constructor(){
    this.cdn = environment.cdn;
  }
  open() {
    const modal = this.modal.nativeElement;
    const body = $('body')
    let dataSCR:any = '';
    if ($(modal).closest('.modal__wrapp').hasClass('video')) {
      dataSCR = $(modal).closest('.modal__wrapp').find('iframe').attr("data-src");
      $(modal).closest('.modal__wrapp').find('iframe').attr("src", dataSCR);
    }
    body.append('<div class="modal__filter"></div>');
    body.addClass('modal__open');
    $(modal).fadeIn();
    $('.modal__filter').fadeIn();
  }
  close() {
    const body = $('body')
    const modal = this.modal.nativeElement;
    body.removeClass('modal__open');
    if ($(modal).closest('.modal__wrapp').hasClass('video')) {
      $(modal).closest('.modal__wrapp').find('iframe').attr("src", "");
    }
    $(modal).closest('.modal__wrapp').fadeOut();
    $('.modal__filter').fadeOut(() => {
      $('.modal__filter').remove();
    })
    this.visible = false
    this.onClose.emit(false)
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible'] !== undefined && changes['visible']['currentValue'] !== undefined && changes['visible']['currentValue'] == true) {
      this.open()
    }
  }
}
