import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AbstractService} from "./abstract.service";

@Injectable({
  providedIn: 'root'
})

export class APIPacienteService extends AbstractService {

  constructor(private http: HttpClient) {
    super('apipaciente');
  }

  public login(data: any) {
    const body = { data: data };
    return this.http.post(`${this.baseUrl}/login/`, body, this.httpOptions);
  }
}
