import { Component, Input } from '@angular/core';
import { CalculadoraService } from '../../services/calculadora.service';
import { ICalculadora } from '../../interfaces/calculadora.interface';
import { ICalculadoraCuotaMensual } from '../../interfaces/calculadoraCuotaMensual.interface';
import { ICalculadoraTin } from '../../interfaces/calculadoraTin.interface';
import { ICalculadoraTae } from '../../interfaces/calculadoraTae.interface';
import { ICalculadoraGastoApertura } from '../../interfaces/calculadoraGastoApertura.interface';
import { ICalculadoraImporteFinanciado } from '../../interfaces/calculadoraImporteFinanciado.interface';
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-calculadora',
  templateUrl: './calculadora.component.html',
  styleUrls: ['./calculadora.component.css']
})
export class CalculadoraComponent {
  @Input() id_calculadora!: number;
  opcion:string = '12';
  color:string = '';
  calculadora: ICalculadora = <ICalculadora>{};
  calculadora_cuota_mensual: ICalculadoraCuotaMensual = <ICalculadoraCuotaMensual>{};
  precio:number = 0;
  cuotaMensual:number = 0;
  cuotaTin:number = 0;
  cuotaTae:number = 0;
  cuotaGastoApertura:number = 0;
  cuotaImporteFinanciado:number = 0;
  opcionA:string = '';
  opcionB:string = '';
  active:number = 0;
  public cdn: string;

  constructor(private _calculadoraService: CalculadoraService){ 
    this.cdn = environment.cdn;
  }

  ngOnInit() { 
    this.color = this.id_calculadora == 6 ? 'green' : 'blue'
    switch (this.id_calculadora) {
      case 5: //precio-cirugia-refractiva
        this.color = 'blue';
        break;
      case 6: //operacion-cataratas y precio-financiacion-operacion-cataratas 
        this.color = 'green';
        this.opcionA = 'Visión Monofocal';
        this.opcionB = 'Visión Multifocal';
        break;
      case 7: //operacion-presbicia y precio-financiacion-operacion-presbicia
        this.color = 'blue';
        this.opcionA = 'Vista Cansada Multifocal';
        this.opcionB = 'Cirugía Láser Visión Combinada';
        break;  
      default:
        break;
    }
     
    this.getCalculadora(this.id_calculadora);
  }

  public getCalculadora(id:number){
    this._calculadoraService.getCalculadora(id).subscribe({
      next: (r: ICalculadora) => {
        this.calculadora = r
        this.getCalculadoraMes(12);
      }
    });    
  }

  public cargarOpcion(num:number){
    this.active = num;
    if (this.id_calculadora == 7)  { // es presbicia
      if (num == 1) { // 0: vista cansada multifocal, 1: cirugia laser combinada
        this.id_calculadora = 10;        
      }
    } else if (this.id_calculadora == 10)  { 
      if (num == 0) { 
        this.id_calculadora = 7;       
      }
    }
  
    if (this.id_calculadora == 6)  { // es catarata
      if (num == 1) { // 0: Vision monofocal, 1: vision multifocal
        this.id_calculadora = 8;
      }
    } else if (this.id_calculadora == 8)  { 
      if (num == 0) { 
        this.id_calculadora = 6;
      }
    }

    this.opcion = '12';
    this.getCalculadora(this.id_calculadora);
  }

  public getCalculadoraMes(mes:any){
    let cuota:number = typeof mes === 'number' && !isNaN(mes) ? mes : parseInt(mes.target.value)
    this.precio = this.calculadora.precio;
    this.cuotaMensual = this.getCuotaMensual(this.calculadora.calculadora_cuota_mensual, cuota);
    this.cuotaTin = this.getCuotaTin(this.calculadora.calculadora_tin, cuota);
    this.cuotaTae = this.getCuotaTae(this.calculadora.calculadora_tae, cuota);
    this.cuotaGastoApertura = this.getCuotaGastoApertura(this.calculadora.calculadora_gastos_apertura, cuota);
    this.cuotaImporteFinanciado = this.getCuotaImporteFinanciado(this.calculadora.calculadora_importe_financiado, cuota);
  }  

  public getCuotaMensual(calculadora_cuota_mensual:ICalculadoraCuotaMensual, mes:number){
    let cuotaMensual:number = 0;
    switch (mes) {
      case 6:
        cuotaMensual = calculadora_cuota_mensual.meses6;
        break;
      case 12:
        cuotaMensual = calculadora_cuota_mensual.meses12;
        break;
      case 18:
        cuotaMensual = calculadora_cuota_mensual.meses18;
        break;
      case 23:
      case 24:
        cuotaMensual = calculadora_cuota_mensual.meses23;
        break;
      case 36:
        cuotaMensual = calculadora_cuota_mensual.meses36;
        break;
      case 48:
        cuotaMensual = calculadora_cuota_mensual.meses48;
        break;
      case 60:
        cuotaMensual = calculadora_cuota_mensual.meses60;
        break;
      default:
        break;
    }
    
    return cuotaMensual;
  }

  public getCuotaTae(calculadora_tae:ICalculadoraTae, mes:number){
    let cuotaTae:number = 0;
    switch (mes) {
      case 6:
        cuotaTae = calculadora_tae.meses6;
        break;
      case 12:
        cuotaTae = calculadora_tae.meses12;
        break;
      case 18:
        cuotaTae = calculadora_tae.meses18;
        break;
      case 24:
        cuotaTae = calculadora_tae.meses24;
        break;
      case 36:
        cuotaTae = calculadora_tae.meses36;
        break;
      case 48:
        cuotaTae = calculadora_tae.meses48;
        break;
      case 60:
        cuotaTae = calculadora_tae.meses60;
        break;
      default:
        break;
    }
    
    return cuotaTae;
  }

  public getCuotaTin(calculadora_tin:ICalculadoraTin, mes:number){
    let cuotaTin:number = 0;
    switch (mes) {
      case 6:
        cuotaTin = calculadora_tin.meses6;
        break;
      case 12:
        cuotaTin = calculadora_tin.meses12;
        break;
      case 18:
        cuotaTin = calculadora_tin.meses18;
        break;
      case 24:
        cuotaTin = calculadora_tin.meses24;
        break;
      case 36:
        cuotaTin = calculadora_tin.meses36;
        break;
      case 48:
        cuotaTin = calculadora_tin.meses48;
        break;
      case 60:
        cuotaTin = calculadora_tin.meses60;
        break;
      default:
        break;
    }
    
    return cuotaTin;
  }

  public getCuotaGastoApertura(calculadora_gasto_apertura:ICalculadoraGastoApertura, mes:number){
    let cuotaGastoApertura:number = 0;
    switch (mes) {
      case 6:
        cuotaGastoApertura = calculadora_gasto_apertura.meses6;
        break;
      case 12:
        cuotaGastoApertura = calculadora_gasto_apertura.meses12;
        break;
      case 18:
        cuotaGastoApertura = calculadora_gasto_apertura.meses18;
        break;
      case 24:
        cuotaGastoApertura = calculadora_gasto_apertura.meses24;
        break;
      case 36:
        cuotaGastoApertura = calculadora_gasto_apertura.meses36;
        break;
      case 48:
        cuotaGastoApertura = calculadora_gasto_apertura.meses48;
        break;
      case 60:
        cuotaGastoApertura = calculadora_gasto_apertura.meses60;
        break;
      default:
        break;
    }
    
    return cuotaGastoApertura;
  }

  public getCuotaImporteFinanciado(calculadora_importe_financiado:ICalculadoraImporteFinanciado, mes:number){
    let cuotaImporteFinanciado:number = 0;
    switch (mes) {
      case 6:
        cuotaImporteFinanciado = calculadora_importe_financiado.meses6;
        break;
      case 12:
        cuotaImporteFinanciado = calculadora_importe_financiado.meses12;
        break;
      case 18:
        cuotaImporteFinanciado = calculadora_importe_financiado.meses18;
        break;
      case 24:
        cuotaImporteFinanciado = calculadora_importe_financiado.meses24;
        break;
      case 36:
        cuotaImporteFinanciado = calculadora_importe_financiado.meses36;
        break;
      case 48:
        cuotaImporteFinanciado = calculadora_importe_financiado.meses48;
        break;
      case 60:
        cuotaImporteFinanciado = calculadora_importe_financiado.meses60;
        break;
      default:
        break;
    }
    
    return cuotaImporteFinanciado;
  }

  public get CalculadoraCuotaMensual(): ICalculadoraCuotaMensual {
    return this.calculadora.calculadora_cuota_mensual;
  }

  public get CalculadoraTin(): ICalculadoraTin {
    return this.calculadora.calculadora_tin;
  }

  public get CalculadoraTae(): ICalculadoraTae {
    return this.calculadora.calculadora_tae;
  }

  public get CalculadoraGastoApertura(): ICalculadoraGastoApertura {
    return this.calculadora.calculadora_gastos_apertura;
  }

  public get CalculadoraImporteFinanciado(): ICalculadoraImporteFinanciado {
    return this.calculadora.calculadora_importe_financiado;
  }
}
