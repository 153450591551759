import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {E404Component} from "./core/components/error/e404/e404.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'cirugia-refractiva',
    loadChildren: () => import('./modules/cirugia-refractiva/cirugia-refractiva.module').then(m => m.CirugiaRefractivaModule),
  },
  {
    path: 'clinica-oftalmologica',
    loadChildren: () => import('./modules/clinica-oftma/clinica-oftma.module').then(m => m.ClinicaOftmaModule),
  },

  {
    path: 'clinica-oftalmologica-medico',
    loadChildren: () => import('./modules/clinica-oftma-medico/clinica-oftma-medico.module').then(m => m.ClinicaOftmaMedicoModule),
  },

  {
    path: 'nuestros-medicos',
    loadChildren: () => import('./modules/nuestros-medicos/nuestros-medicos.module').then(m => m.NuestrosMedicosModule),
  },
  {
    path: 'unidad-de-retina',
    loadChildren: () => import('./modules/unidad-de-retina/unidad-de-retina.module').then(m => m.UnidadDeRetinaModule),
  },
  {
    path: 'que-es-la-unidad-de-retina',
    loadChildren: () => import('./modules/que-es-la-unidad-de-retina/que-es-la-unidad-de-retina.module').then(m => m.QueEsLaUnidadDeRetinaModule),
  },
  {
    path: 'oftalmologos-unidad-de-retina',
    loadChildren: () => import('./modules/oftalmologos-unidad-retina/oftalmologos-unidad-retina.module').then(m => m.OftalmologosUnidadRetinaModule),
  },
  {
    path: 'agujero-macular',
    loadChildren: () => import('./modules/agujero-macular/agujero-macular.module').then(m => m.AgujeroMacularModule),
  },
  {
    path: 'coroiditis-central-serosa',
    loadChildren: () => import('./modules/coroiditis-central-serosa/coroiditis-central-serosa.module').then(m => m.CoroiditisCentralSerosaModule),
  },
  {
    path: 'desprendimiento-retina',
    loadChildren: () => import('./modules/desprendimiento-retina/desprendimiento-retina.module').then(m => m.DesprendimientoRetinaModule),
  },
  {
    path: 'degeneracion-macular-asociada-edad',
    loadChildren: () => import('./modules/degeneracion-macular-asociada-edad/degeneracion-macular-asociada-edad.module').then(m => m.DegeneracionMacularAsociadaEdadModule),
  },
  {
    path: 'membrana-epirretiniana',
    loadChildren: () => import('./modules/membrana-epirretiniana/membrana-epirretiniana.module').then(m => m.MembranaEpirretinianaModule),
  },
  {
    path: 'miopia-magna',
    loadChildren: () => import('./modules/miopia-magna/miopia-magna.module').then(m => m.MiopiaMagnaModule),
  },
  {
    path: 'retinopatia-diabetica',
    loadChildren: () => import('./modules/retinopatia-diabetica/retinopatia-diabetica.module').then(m => m.RetinopatiaDiabeticaModule),
  },
  {
    path: 'oclusiones-venosas-retinas',
    loadChildren: () => import('./modules/oclusiones-venosas-retinas/oclusiones-venosas-retinas.module').then(m => m.OclusionesVenosasRetinasModule),
  },
  {
    path: 'desprendimiento-posterior-vitreo',
    loadChildren: () => import('./modules/desprendimiento-posterior-vitreo/desprendimiento-posterior-vitreo.module').then(m => m.DesprendimientoPosteriorVitreoModule),
  },
  {
    path: 'retinosis-pigmentaria',
    loadChildren: () => import('./modules/retinosis-pigmentaria/retinosis-pigmentaria.module').then(m => m.RetinosisPigmentariaModule),
  },
  {
    path: 'cura-retinosis-pigmentaria',
    loadChildren: () => import('./modules/cura-retinosis-pigmentaria/cura-retinosis-pigmentaria.module').then(m => m.CuraRetinosisPigmentariaModule),
  },
  {
    path: 'tratamiento-vitrectomia',
    loadChildren: () => import('./modules/tratamiento-vitrectomia/tratamiento-vitrectomia.module').then(m => m.TratamientoVitrectomiaModule),
  },
  {
    path: 'operacion-presbicia',
    loadChildren: () => import('./modules/operacion-presbicia/operacion-presbicia.module').then(m => m.OperacionPresbiciaModule),
  },
  {
    path: 'tratamiento-presbicia',
    loadChildren: () => import('./modules/tratamiento-presbicia/tratamiento-presbicia.module').then(m => m.TratamientoPresbiciaModule),
  },
  {
    path: 'precio-financiacion-operacion-presbicia',
    loadChildren: () => import('./modules/precio-financiacion-operacion-presbicia/precio-financiacion-operacion-presbicia.module').then(m => m.PrecioFinanciacionOperacionPresbiciaModule),
  },
  {
    path: 'operacion-presbicia-primera-consulta',
    loadChildren: () => import('./modules/operacion-presbicia-primera-consulta/operacion-presbicia-primera-consulta.module').then(m => m.OperacionPresbiciaPrimeraConsultaModule),
  },
  {
    path: 'cansada-resultados',
    loadChildren: () => import('./modules/cansada-resultados/cansada-resultados.module').then(m => m.CansadaResultadosModule),
  },
  {
    path: 'presbicia-medicos',
    loadChildren: () => import('./modules/presbicia-medicos/presbicia-medicos.module').then(m => m.PresbiciaMedicosModule),
  },
  {
    path: 'miopia-y-presbicia',
    loadChildren: () => import('./modules/miopia-y-presbicia/miopia-y-presbicia.module').then(m => m.MiopiaYPresbiciaModule),
  },
  {
    path: 'sintomas-vista-cansada',
    loadChildren: () => import('./modules/sintomas-vista-cansada/sintomas-vista-cansada.module').then(m => m.SintomasVistaCansadaModule),
  },
  {
    path: 'lentes-intraoculares-trifocales',
    loadChildren: () => import('./modules/lentes-intraoculares-trifocales/lentes-intraoculares-trifocales.module').then(m => m.LentesIntraocularesTrifocalesModule),
  },
  {
    path: 'operacion-cataratas',
    loadChildren: () => import('./modules/operacion-cataratas/operacion-cataratas.module').then(m => m.OperacionCataratasModule),
  },
  {
    path: 'tratamiento-cataratas',
    loadChildren: () => import('./modules/tratamiento-cataratas/tratamiento-cataratas.module').then(m => m.TratamientoCataratasModule),
  },
  {
    path: 'precio-financiacion-operacion-cataratas',
    loadChildren: () => import('./modules/precio-financiacion-operacion-cataratas/precio-financiacion-operacion-cataratas.module').then(m => m.PrecioFinanciacionOperacionCataratasModule),
  },
  {
    path: 'primera-consulta-cataratas',
    loadChildren: () => import('./modules/primera-consulta-cataratas/primera-consulta-cataratas.module').then(m => m.PrimeraConsultaCataratasModule),
  },
  {
    path: 'cataratas-medicos',
    loadChildren: () => import('./modules/cataratas-medicos/cataratas-medicos.module').then(m => m.CataratasMedicosModule),
  },
  {
    path: 'operacion-cataratas-postoperatorio',
    loadChildren: () => import('./modules/operacion-cataratas-postoperatorio/operacion-cataratas-postoperatorio.module').then(m => m.OperacionCataratasPostoperatorioModule),
  },
  {
    path: 'desprendimiento-posterior-vitreo',
    loadChildren: () => import('./modules/desprendimiento-posterior-vitreo/desprendimiento-posterior-vitreo.module').then(m => m.DesprendimientoPosteriorVitreoModule),
  },
  {
    path: 'el-edema-macular-diabetico',
    loadChildren: () => import('./modules/el-edema-macular-diabetico/el-edema-macular-diabetico.module').then(m => m.ElEdemaMacularDiabeticoModule),
  },
  {
    path: 'desprendimiento-retina-postoperatorio',
    loadChildren: () => import('./modules/desprendimiento-retina-postoperatorio/desprendimiento-retina-postoperatorio.module').then(m => m.DesprendimientoRetinaPostoperatorioModule),
  },
  {
    path: 'otros-tratamientos',
    loadChildren: () => import('./modules/otros-tratamientos/otros-tratamientos.module').then(m => m.OtrosTratamientosModule),
  },
  {
    path: 'refractiva-miopia',
    loadChildren: () => import('./modules/refractiva-miopia/refractiva-miopia.module').then(m => m.RefractivaMiopiaModule),
  },
  {
    path: 'refractiva-hipermetropia',
    loadChildren: () => import('./modules/refractiva-hipermetropia/refractiva-hipermetropia.module').then(m => m.RefractivaHipermetropiaModule),
  },
  {
    path: 'refractiva-astigmatismo',
    loadChildren: () => import('./modules/refractiva-astigmatismo/refractiva-astigmatismo.module').then(m => m.RefractivaAstigmatismoModule),
  },
  {
    path: 'tratamiento-miopia-hipermetropia-astigmatismo',
    loadChildren: () => import('./modules/tratamiento-miopia-hipermetropia-astigmatismo/tratamiento-miopia-hipermetropia-astigmatismo.module').then(m => m.TratamientoMiopiaHipermetropiaAstigmatismoModule),
  },
  {
    path: 'lasik',
    loadChildren: () => import('./modules/lasik/lasik.module').then(m => m.LasikModule),
  },
  {
    path: 'lasek',
    loadChildren: () => import('./modules/lasek/lasek.module').then(m => m.LasekModule),
  },
  {
    path: 'precio-cirugia-refractiva',
    loadChildren: () => import('./modules/precio-cirugia-refractiva/precio-cirugia-refractiva.module').then(m => m.PrecioCirugiaRefractivaModule),
  },
  {
    path: 'cirugia-refractiva-primera-consulta',
    loadChildren: () => import('./modules/cirugia-refractiva-primera-consulta/cirugia-refractiva-primera-consulta.module').then(m => m.CirugiaRefractivaPrimeraConsultaModule),
  },
  {
    path: 'oftalmologia-pediatrica',
    loadChildren: () => import('./modules/oftalmologia-pediatrica/oftalmologia-pediatrica.module').then(m => m.OftalmologiaPediatricaModule),
  },
  {
    path: 'glaucoma',
    loadChildren: () => import('./modules/glaucoma/glaucoma.module').then(m => m.GlaucomaModule),
  },
  {
    path: 'estrabismo',
    loadChildren: () => import('./modules/estrabismo/estrabismo.module').then(m => m.EstrabismoModule),
  },
  {
    path: 'cirugia-laser-resultados',
    loadChildren: () => import('./modules/cirugia-laser-resultados/cirugia-laser-resultados.module').then(m => m.CirugiaLaserResultadosModule),
  },
  {
    path: 'cirugia-refractiva-altas-graduaciones',
    loadChildren: () => import('./modules/cirugia-refractiva-altas-graduaciones/cirugia-refractiva-altas-graduaciones.module').then(m => m.CirugiaRefractivaAltasGraduacionesModule),
  },
  {
    path: 'refractiva-medicos',
    loadChildren: () => import('./modules/refractiva-medicos/refractiva-medicos.module').then(m => m.RefractivaMedicosModule),
  },
  {
    path: 'operacion-miopia-madrid',
    loadChildren: () => import('./modules/operacion-miopia-madrid/operacion-miopia-madrid.module').then(m => m.OperacionMiopiaMadridModule),
  },
  {
    path: 'operacion-de-miopia',
    loadChildren: () => import('./modules/operacion-de-miopia/operacion-de-miopia.module').then(m => m.OperacionDeMiopiaModule),
  },
  {
    path: 'astigmatismo-y-miopia',
    loadChildren: () => import('./modules/astigmatismo-y-miopia/astigmatismo-y-miopia.module').then(m => m.AstigmatismoYMiopiaModule),
  },
  {
    path: 'sintomas-hipermetropia',
    loadChildren: () => import('./modules/sintomas-hipermetropia/sintomas-hipermetropia.module').then(m => m.SintomasHipermetropiaModule),
  },
  {
    path: 'hipermetropia-astigmatismo',
    loadChildren: () => import('./modules/hipermetropia-astigmatismo/hipermetropia-astigmatismo.module').then(m => m.HipermetropiaAstigmatismoModule),
  },
  {
    path: 'tipos-cirugia-ocular',
    loadChildren: () => import('./modules/tipos-cirugia-ocular/tipos-cirugia-ocular.module').then(m => m.TiposCirugiaOcularModule),
  },
  {
    path: 'lentes-intraoculares-miopia',
    loadChildren: () => import('./modules/lentes-intraoculares-miopia/lentes-intraoculares-miopia.module').then(m => m.LentesIntraocularesMiopiaModule),
  },
  {
    path: 'cornea-queratocono',
    loadChildren: () => import('./modules/cornea-queratocono/cornea-queratocono.module').then(m => m.CorneaQueratoconoModule),
  },
  {
    path: 'alteraciones-neuroftalmologicas',
    loadChildren: () => import('./modules/alteraciones-neuroftalmologicas/alteraciones-neuroftalmologicas.module').then(m => m.AlteracionesNeuroftalmologicasModule),
  },
  {
    path: 'aparato-lagrimal',
    loadChildren: () => import('./modules/aparato-lagrimal/aparato-lagrimal.module').then(m => m.AparatoLagrimalModule),
  },
  {
    path: 'otros_tratamientos_oculoplastia',
    loadChildren: () => import('./modules/otros-tratamientos-oculoplastia/otros-tratamientos-oculoplastia.module').then(m => m.OtrosTratamientosOculoplastiaModule),
  },
  {
    path: 'patologias-oculares-ninos',
    loadChildren: () => import('./modules/patologias-oculares-ninos/patologias-oculares-ninos.module').then(m => m.PatologiasOcularesNinosModule),
  },
  {
    path: 'donde-estamos',
    loadChildren: () => import('./modules/donde-estamos/donde-estamos.module').then(m => m.DondeEstamosModule),
  },
  {
    path: 'empresa-responsable',
    loadChildren: () => import('./modules/empresa-responsable/empresa-responsable.module').then(m => m.EmpresaResponsableModule),
  },
  {
    path: 'responsabilidad-empleados',
    loadChildren: () => import('./modules/responsabilidad-empleados/responsabilidad-empleados.module').then(m => m.ResponsabilidadEmpleadosModule),
  },
  {
    path: 'responsabilidad-pacientes',
    loadChildren: () => import('./modules/responsabilidad-pacientes/responsabilidad-pacientes.module').then(m => m.ResponsabilidadPacientesModule),
  },
  {
    path: 'responsabilidad-sociedad',
    loadChildren: () => import('./modules/responsabilidad-sociedad/responsabilidad-sociedad.module').then(m => m.ResponsabilidadSociedadModule),
  },
  {
    path: 'ojo-vago-ambliopia',
    loadChildren: () => import('./modules/ojo-vago/ojo-vago.module').then(m => m.OjoVagoModule),
  },
  {
    path: 'migrana-ocular-oftalmica',
    loadChildren: () => import('./modules/migrana-ocular-oftalmica/migrana-ocular-oftalmica.module').then(m => m.MigranaOcularOftalmicaModule),
  },
  /*{
    path: 'migrana-con-aura',
    loadChildren: () => import('./modules/migrana-con-aura/migrana-con-aura.module').then(m => m.MigranaConAuraModule),
  },*/
  {
    path: 'neuritis-optica',
    loadChildren: () => import('./modules/neuritis-optica/neuritis-optica.module').then(m => m.NeuritisOpticaModule),
  },
  {
    path: 'nervio-optico-danado',
    loadChildren: () => import('./modules/nervio-optico-danado/nervio-optico-danado.module').then(m => m.NervioOpticoDanadoModule),
  },
  {
    path: 'blefaroespasmo-temblor-ojo',
    loadChildren: () => import('./modules/blefaroespasmo-temblor-ojo/blefaroespasmo-temblor-ojo.module').then(m => m.BlefaroespasmoTemblorOjoModule),
  },
  {
    path: 'vias-lagrimales',
    loadChildren: () => import('./modules/vias-lagrimales/vias-lagrimales.module').then(m => m.ViasLagrimalesModule),
  },
  {
    path: 'clinicas-provincia',
    loadChildren: () => import('./modules/clinicas-provincia/clinicas-provincia.module').then(m => m.ClinicasProvinciaModule),
  },
  {
    path: 'dacrioadenitis-tratamiento',
    loadChildren: () => import('./modules/dacrioadenitis-tratamiento/dacrioadenitis-tratamiento.module').then(m => m.DacrioadenitisTratamientoModule),
  },
  {
    path: 'clinica-oftalmologica-retina',
    loadChildren: () => import('./modules/clinica-oftalmologica-retina/clinica-oftalmologica-retina.module').then(m => m.ClinicaOftalmologicaRetinaModule),
  },
  {
    path: 'degeneracion-macular-jovenes',
    loadChildren: () => import('./modules/degeneracion-macular-jovenes/degeneracion-macular-jovenes.module').then(m => m.DegeneracionMacularJovenesModule),
  },
  {
    path: 'prueba-oct-macular',
    loadChildren: () => import('./modules/prueba-oct-macular/prueba-oct-macular.module').then(m => m.PruebaOctMacularModule),
  },
  {
    path: 'xantelasmas',
    loadChildren: () => import('./modules/xantelasmas/xantelasmas.module').then(m => m.XantelasmasModule),
  },
  {
    path: 'entropion',
    loadChildren: () => import('./modules/entropion/entropion.module').then(m => m.EntropionModule),
  },
  {
    path: 'la-retinopatia-hipertensiva',
    loadChildren: () => import('./modules/la-retinopatia-hipertensiva/la-retinopatia-hipertensiva.module').then(m => m.LaRetinopatiaHipertensivaModule),
  },
  {
    path: 'clinica-baviera',
    loadChildren: () => import('./modules/clinica-baviera/clinica-baviera.module').then(m => m.ClinicaBavieraModule),
  },
  {
    path: 'ectropion',
    loadChildren: () => import('./modules/ectropion/ectropion.module').then(m => m.EctropionModule),
  },
  {
    path: 'ptosis-palpebral',
    loadChildren: () => import('./modules/ptosis-palpebral/ptosis-palpebral.module').then(m => m.PtosisPalpebralModule),
  },
  {
    path: 'anisometropia-aniseiconia',
    loadChildren: () => import('./modules/anisometropia-aniseiconia/anisometropia-aniseiconia.module').then(m => m.AnisometropiaAniseiconiaModule),
  },
  {
    path: 'operacion-estrabismo',
    loadChildren: () => import('./modules/operacion-estrabismo/operacion-estrabismo.module').then(m => m.OperacionEstrabismoModule),
  },
  {
    path: 'cross-linking-corneal',
    loadChildren: () => import('./modules/cross-linking-corneal/cross-linking-corneal.module').then(m => m.CrossLinkingCornealModule),
  },
  {
    path: 'queratitis-o-ulcera-corneal',
    loadChildren: () => import('./modules/queratitis-o-ulcera-corneal/queratitis-o-ulcera-corneal.module').then(m => m.QueratitisOUlceraCornealModule),
  },
  {
    path: 'anillos-intracorneales-intraestromales',
    loadChildren: () => import('./modules/anillos-intracorneales-intraestromales/anillos-intracorneales-intraestromales.module').then(m => m.AnillosIntracornealesIntraestromalesModule),
  },
  {
    path: 'blefaroplastia-y-su-precio',
    loadChildren: () => import('./modules/blefaroplastia-y-su-precio/blefaroplastia-y-su-precio.module').then(m => m.BlefaroplastiaYSuPrecioModule),
  },
  {
    path: 'pterigion-tratamiento',
    loadChildren: () => import('./modules/pterigion-tratamiento/pterigion-tratamiento.module').then(m => m.PterigionTratamientoModule),
  },
  {
    path: 'dacriocistorrinostomia',
    loadChildren: () => import('./modules/dacriocitorrinostomia/dacriocitorrinostomia.module').then(m => m.DacriocitorrinostomiaModule),
  },
  {
    path: 'trasplante-cornea-o-queratoplastia',
    loadChildren: () => import('./modules/trasplante-cornea-o-queratoplastia/trasplante-cornea-o-queratoplastia.module').then(m => m.TrasplanteCorneaOQueratoplastiaModule),
  },
  {
    path: 'epifora-ocular-lagrimeo',
    loadChildren: () => import('./modules/epifora-ocular-lagrimeo/epifora-ocular-lagrimeo.module').then(m => m.EpiforaOcularLagrimeoModule),
  },
  {
    path: 'evisceracion-y-enucleacion-ocular',
    loadChildren: () => import('./modules/evisceracion-y-enucleacion-ocular/evisceracion-y-enucleacion-ocular.module').then(m => m.EvisceracionYEnucleacionOcularModule),
  },
  {
    path: 'paralisis-facial',
    loadChildren: () => import('./modules/paralisis-facial/paralisis-facial.module').then(m => m.ParalisisFacialModule),
  },
  {
    path: 'tumores-oculares',
    loadChildren: () => import('./modules/tumores-oculares/tumores-oculares.module').then(m => m.TumoresOcularesModule),
  },
  {
    path: 'ptosis-de-ceja',
    loadChildren: () => import('./modules/ptosis-de-ceja/ptosis-de-ceja.module').then(m => m.PtosisDeCejaModule),
  },
  {
    path: 'cefalea-ocular',
    loadChildren: () => import('./modules/cefalea-ocular/cefalea-ocular.module').then(m => m.CefaleaOcularModule),
  },
  {
    path: 'dacriocistografia',
    loadChildren: () => import('./modules/dacriocistografia/dacriocistografia.module').then(m => m.DacriocistografiaModule),
  },
  {
    path: 'dacriocistitis-tratamiento',
    loadChildren: () => import('./modules/dacriocistitis-tratamiento/dacriocistitis-tratamiento.module').then(m => m.DacriocistitisTratamientoModule),
  },
  {
    path: 'estrabismo-en-adultos-y-operacion',
    loadChildren: () => import('./modules/estrabismo-en-adultos-y-operacion/estrabismo-en-adultos-y-operacion.module').then(m => m.EstrabismoEnAdultosYOperacionModule),
  },
  {
    path: 'ojo-seco',
    loadChildren: () => import('./modules/ojo-seco/ojo-seco.module').then(m => m.OjoSecoModule),
  },
  {
    path: 'otros-tratamientos-medicos',
    loadChildren: () => import('./modules/otros-tratamientos-medicos/otros-tratamientos-medicos.module').then(m => m.OtrosTratamientosMedicosModule),
  },
  {
    path: 'distrofia-corneal',
    loadChildren: () => import('./modules/distrofias-corneales/distrofias-corneales.module').then(m => m.DistrofiasCornealesModule),
  },
  {
    path: 'clinica-baviera-idi',
    loadChildren: () => import('./modules/clinica-baviera-idi/clinica-baviera-idi.module').then(m => m.ClinicaBavieraIdiModule),
  },
  {
    path: 'prensa-contacto',
    loadChildren: () => import('./modules/prensa-contacto/prensa-contacto.module').then(m => m.PrensaContactoModule),
  },
  {
    path: 'condiciones',
    loadChildren: () => import('./modules/condiciones/condiciones.module').then(m => m.CondicionesModule),
  },
  {
    path: 'aviso-legal',
    loadChildren: () => import('./modules/aviso-legal/aviso-legal.module').then(m => m.AvisoLegalModule),
  },
  {
    path: 'cookies',
    loadChildren: () => import('./modules/cookies/cookies.module').then(m => m.CookiesModule),
  },
  {
    path: 'sitemaps',
    loadChildren: () => import('./modules/sitemaps/sitemaps.module').then(m => m.SitemapsModule),
  },
  {
    path: 'seguros',
    loadChildren: () => import('./modules/seguros/seguros.module').then(m => m.SegurosModule),
  },
  {
    path: 'por-que-baviera',
    loadChildren: () => import('./modules/por-que-baviera/por-que-baviera.module').then(m => m.PorQueBavieraModule),
  },
  {
    path: 'cinco-razones-elegirnos',
    loadChildren: () => import('./modules/cinco-razones-elegirnos/cinco-razones-elegirnos.module').then(m => m.CincoRazonesElegirnosModule),
  },
  {
    path: 'laser-correction',
    loadChildren: () => import('./modules/laser-correction/laser-correction.module').then(m => m.LaserCorrectionModule),
  },
  {
    path: 'clinica-baviera-reclamaciones-quejas',
    loadChildren: () => import('./modules/clinica-baviera-reclamaciones-quejas/clinica-baviera-reclamaciones-quejas.module').then(m => m.ClinicaBavieraReclamacionesQuejasModule),
  },
  {
    path: 'clinica-baviera-red',
    loadChildren: () => import('./modules/clinica-baviera-red/clinica-baviera-red.module').then(m => m.ClinicaBavieraRedModule),
  },
  {
    path: 'international',
    loadChildren: () => import('./modules/international/international.module').then(m => m.InternationalModule),
  },
  {
    path: 'lens-implantation',
    loadChildren: () => import('./modules/lens-implantation/lens-implantation.module').then(m => m.LensImplantationModule),
  },
  {
    path: 'retinal-diseases',
    loadChildren: () => import('./modules/retinal-diseases/retinal-diseases.module').then(m => m.RetinalDiseasesModule),
  },
  {
    path: 'trabajo-empleo',
    loadChildren: () => import('./modules/trabajo-empleo/trabajo-empleo.module').then(m => m.TrabajoEmpleoModule),
  },
  {
    path: 'queratocono-tratamiento',
    loadChildren: () => import('./modules/queratocono-tratamiento/queratocono-tratamiento.module').then(m => m.QueratoconoTratamientoModule),
  },
  {
    path: 'clinica-baviera-compromiso-social',
    loadChildren: () => import('./modules/clinica-baviera-compromiso-social/clinica-baviera-compromiso-social.module').then(m => m.ClinicaBavieraCompromisoSocialModule),
  },
  {
    path: 'operacion-miopia-sevilla',
    loadChildren: () => import('./modules/operacion-miopia-sevilla/operacion-miopia-sevilla.module').then(m => m.OperacionMiopiaSevillaModule),
  },
  {
    path: 'resultados-cirugia-cataratas',
    loadChildren: () => import('./modules/resultados-cirugia-cataratas/resultados-cirugia-cataratas.module').then(m => m.ResultadosCirugiaCataratasModule),
  },
  {
    path: 'plan-de-igualdad',
    loadChildren: () => import('./modules/plan-de-igualdad/plan-de-igualdad.module').then(m => m.PlanDeIgualdadModule),
  },

  {
    path: 'nistagmus',
    loadChildren: () => import('./modules/nistagmus/nistagmus.module').then(m => m.NistagmusModule),
  },
  {
    path: 'retraccion-palpebral',
    loadChildren: () => import('./modules/retraccion-palpebral/retraccion-palpebral.module').then(m => m.RetraccionPalpebralModule),
  },
  {
    path: 'orbitopatia-tiroidea',
    loadChildren: () => import('./modules/orbitopatia-tiroidea/orbitopatia-tiroidea.module').then(m => m.OrbitopatiaTiroideaModule),
  },
  {
    path: 'e404', component: E404Component
  },

  {
    path: '**', redirectTo: 'e404'
  },

  // {
  //   path: "**",
  //   redirectTo: ""
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
