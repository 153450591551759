<div gsAppear class="item__contenido-medico ">
    <img src="{{cdn}}imagenes/medicos/img-gonzalo.png" alt="">
    <div>   
    <p class="item__contenido-medico-autor">Este contenido ha sido elaborado por el <strong>Dr. Gonzalo Muñoz</strong></p>


    <p><strong>Oftalmólogo. Director Médico de Clínica Baviera España. Cirujano de las Unidades de Cirugía Refractiva, Cataratas, Presbicia y Glaucoma.</strong></p>
    <br class="visible-lg">
    <p>El Dr. Muñoz cuenta con una amplia experiencia en procedimientos de cirugía refractiva láser (Lasik, Lasek y PRK), cirugía intraocular, incluyendo cataratas e intercambio refractivo de cristalino (con lentes monofocales, multifocales y tóricas) y cirugía del glaucoma. Además, ha realizado más de 110 publicaciones en revistas indexadas con factor de impacto y ha participado en múltiples proyectos de ayuda humanitaria en países en vías de desarrollo con el objetivo de luchar contra la ceguera evitable.</p>
    <a href="clinica-oftalmologica-medico/gonzalo-munoz" class="item__contenido-medico-cv">Ver CV</a>
    </div>
</div>
