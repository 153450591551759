import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {AbstractService} from "./abstract.service";

@Injectable({
  providedIn: 'root'
})

export class PresenceService extends AbstractService {

  constructor(private http: HttpClient) {
    super('presence');
  }

  public getToken(): Observable<any> {
    return this.http.post(`${this.baseUrl}/token/`, this.httpOptions)
  }

  public sendForm(data: any, token: any) {
    this.httpOptions = this.buildOptions(token);
    const body = { data: data, token: token };
    return this.http.post(`${this.baseUrl}/record/`, body, this.httpOptions);
  }
}
