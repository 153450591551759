import { Component } from '@angular/core';
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-box-medico-carlos-laria',
  templateUrl: './box-medico-carlos-laria.component.html',
  styleUrls: ['./box-medico-carlos-laria.component.css']
})
export class BoxMedicoCarlosLariaComponent {
  public cdn: string;
  constructor() {
    this.cdn = environment.cdn;
   }
}
