<a href="" #formOpen (click)="toggleForm($event)">
    <img src="{{cdn}}imagenes/promo/refractiva-cabecera-desktop.svg" alt="" class="item__promo-cabecera-imagen"  >
</a>
    
    <div class="item__promo-cabecera-consulta visible-xs">
        <div> 
                <a #formOpen (click)="toggleForm($event)">
                    <picture>
                        <img src="{{cdn}}imagenes/promo/img-promo-mobile-persona-presbicia.png"
                            alt="">
                    </picture>
                </a>
    
                <div class="content__title">
                    <a #formOpen (click)="toggleForm($event)" class="item__title">
                        <strong>1ª consulta <br><span>Gratis</span></strong>
                    </a>
                    <a #formOpen (click)="toggleForm($event)" class="item__text">
                        <strong>(Ahórrate 120&euro;) </strong>
                    </a>
                    
    
                </div>
            </div>
        <a href="" #formOpen (click)="toggleForm($event)" class="btn-orange ">Pedir cita sin compromiso</a>
        <div class=" u-margin-bottom-xs">
        <p class="condiciones-mobile">Precio de la 1ª Consulta valorada en 120€. No acumulable a otras ofertas. Promoción válida hasta el 31/12/2025 para consultas preoperatorias de miopía, hipermetropía, astigmatismo, presbicia y cataratas (quedan excluidas consultas de otras especialidades). Pruebas incluidas. Promoción válida salvo errores tipográficos u ortográficos. Más info en</p>
        <a  #btnCondiciones (click)="showCondiciones($event)"  class="item__legales">
            <strong>Ver más</strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" class="u-margin-left-xs">
                    <circle cx="8" cy="8.5" r="8" fill="#00499A"/>
                    <path d="M4.52769 7.66039C4.52747 7.7206 4.53823 7.78051 4.55959 7.83684L4.71919 7.77631L4.55959 7.83684C4.58107 7.89347 4.61301 7.94576 4.65414 7.99041L4.65461 7.99092L6.94519 10.4577C7.09063 10.6147 7.2641 10.74 7.45602 10.8258C7.64799 10.9116 7.85428 10.9559 8.06297 10.9559C8.27166 10.9559 8.47795 10.9116 8.66992 10.8258C8.86181 10.7401 9.03525 10.6148 9.18068 10.4578C9.1807 10.4578 9.18073 10.4577 9.18075 10.4577L11.4713 7.99092C11.5546 7.90125 11.5994 7.78216 11.5994 7.66039C11.5994 7.53863 11.5546 7.41953 11.4713 7.32986C11.3877 7.23976 11.2714 7.18652 11.1472 7.18652C11.023 7.18652 10.9067 7.23976 10.823 7.32986L8.53237 9.79672C8.53236 9.79674 8.53234 9.79676 8.53232 9.79677C8.53232 9.79678 8.53231 9.79679 8.5323 9.7968C8.40546 9.93318 8.23636 10.0072 8.06297 10.0072C7.88958 10.0072 7.72048 9.93318 7.59364 9.7968C7.59363 9.79678 7.59362 9.79677 7.59361 9.79676C7.59359 9.79675 7.59358 9.79673 7.59357 9.79672L5.30339 7.33037C5.3033 7.33028 5.30321 7.33018 5.30312 7.33008C5.26189 7.28539 5.21217 7.24917 5.15646 7.22418C5.10058 7.19911 5.04015 7.186 4.97876 7.186C4.91737 7.186 4.85693 7.19911 4.80106 7.22418C4.74522 7.24923 4.69541 7.28555 4.65413 7.33037C4.61301 7.37503 4.58107 7.42731 4.55959 7.48394L4.71919 7.54447L4.55959 7.48394C4.53823 7.54027 4.52747 7.60018 4.52769 7.66039ZM4.52769 7.66039C4.52769 7.66008 4.52769 7.65978 4.52769 7.65947L4.69838 7.66039L4.52769 7.66131C4.52769 7.661 4.52769 7.6607 4.52769 7.66039Z" fill="white" stroke="white" stroke-width="0.341379"/>
                </svg>
            </a>
        </div>
    </div>
 
    