import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {IFormInternacional} from "../interfaces/formInternacional.interface";
import {AbstractService} from "./abstract.service";

@Injectable({
  providedIn: 'root'
})

export class InternationalFormService extends AbstractService {

  constructor(private http: HttpClient) {
    super('forminternacional');
  }

   public sendSolicitud(request: IFormInternacional): Observable<any> {
    return this.http.post<Observable<any>>(this.baseUrl, request)
   }

   public updateSolicitud(id: number, request: object): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}`, request);
  }
}