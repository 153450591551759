import { Component, Input, OnInit } from '@angular/core';
import { ClinicService } from '../../services/clinic.service';
import { ActivatedRoute } from '@angular/router';
import { IClinic } from '../../interfaces/clinic.interface';
import { IProvince } from "../../interfaces/province.interface";
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-clinicas-por-provincia',
  templateUrl: './clinicas-por-provincia.component.html',
  styleUrls: ['./clinicas-por-provincia.component.css']
})
export class ClinicasPorProvinciaComponent {
  @Input() province!: IProvince
  public cdn: string;
  
  constructor() {
    this.cdn = environment.cdn;
   }

  public get clinics(): IClinic[] {
    return this.province.clinicas
  }

  public esPoblacion(cp: string): boolean {
    return false && (parseInt(cp) % 1000) > 99
  }

}
