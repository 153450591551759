<img src="{{cdn}}imagenes/img-calculadora-{{color}}.svg" alt="" style="max-width: 61px" class="img-calc">
<p class="item__title"><strong>Calculadora de financiación</strong></p>

<div *ngIf="id_calculadora == 6 || id_calculadora == 7 || id_calculadora == 8 || id_calculadora == 10" class="item__seleccion-operacion">
    <a (click)="cargarOpcion(0)" [ngClass]="{'active': !active}" class="active"><strong>{{ opcionA }}</strong></a>
    <a (click)="cargarOpcion(1)" [ngClass]="{'active': active}" ><strong>{{ opcionB }}</strong></a>
</div>

<p class="subtitle"> <strong>Selecciona los meses:</strong></p>
<select name="mes" id="" class="item__select-meses" [(ngModel)]="opcion" (change)="getCalculadoraMes($event)">
    <option value="12">12 meses</option>
    <option value="18">18 meses</option>
    <option value="24">24 meses</option>
    <option value="36">36 meses</option>
    <option value="48">48 meses</option>
    <option value="60">60 meses</option>
</select>
<p class="subtitle"><strong>Resultado Financiación:</strong></p>
<ul>
    <li><span>Importe Financiado</span><span>{{ precio }} &euro;</span></li>
    <li><span>Cuota de apertura</span><span>{{ cuotaGastoApertura }} &euro;</span></li>
    <li><span>Intereses de TIN</span><span>{{ cuotaTin }}%</span></li>
</ul>
<div class="item__cuota">
    <p>Cuota por ojo al mes</p>
    <p class="bigger">{{ cuotaMensual }} &euro;</p>
</div>


