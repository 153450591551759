import { Component } from '@angular/core';
import { SLIDE_CONFIG } from '../../utils/const/slideConfig.const';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-refractiva-opiniones',
  templateUrl: './refractiva-opiniones.component.html',
  styleUrls: ['./refractiva-opiniones.component.css']
})
export class RefractivaOpinionesComponent {
  public slideConfigOpiniones: object
  public cdn: string;
  constructor() {
    this.slideConfigOpiniones = SLIDE_CONFIG.rSurgeryMainComponent
    this.cdn = environment.cdn;
  }
}
