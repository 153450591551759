<div>
  <div #modal class="modal__wrapp video {{infografia}}" >
    <div class="modal__inner">
      <div class="modal">
        <span (click)="close()" class="close">
          <img src="{{cdn}}imagenes/icons/icon-close-round-blue.png" alt="">
        </span>
        <div *ngIf="header">
          {{ header}}
        </div>
        <ng-content>
        </ng-content>
        <p *ngIf="footer" class="item__modal-nota">
          <strong [innerHTML]="footer"></strong>
        </p>
      </div>
    </div>
  </div>
</div>