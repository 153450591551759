<div *ngIf="comparisonItem"  class="image-comparison"
     #imageComparisonSlider
     data-component="image-comparison-slider">

  <div class="image-comparison__slider-wrapper">

    <input type="range"
           #sliderRange
           min="0"
           max="100"
           value="50"
           (mouseup)="onMouseUp($event)"
           (input)="onInput($event)"
           (change)="onChange($event)"
           class="image-comparison__range"
           id="image-compare-range"
           data-image-comparison-range="">

    <div class="image-comparison__image-wrapper  image-comparison__image-wrapper--overlay"
         #imageWrapperOverlay
         data-image-comparison-overlay="">

      <figure class="image-comparison__figure image-comparison__figure--overlay">
        <picture class="image-comparison__picture">
          <img
            [src]="comparisonItem.imgR ? comparisonItem.imgR.url : comparisonItem.imgL.url"
            [alt]="comparisonItem.imgR ? comparisonItem.imgR.alt : comparisonItem.imgL.alt"
            class="image-comparison__image">
        </picture>

        <figcaption *ngIf="comparisonItem.imgR?.label && showLabel"
                    class="image-comparison__caption image-comparison__caption--before">
          <span  class="image-comparison__caption-body">
             {{comparisonItem.imgR?.label}}
          </span>
        </figcaption>
      </figure>
    </div>

    <div #comparisonSlider class="image-comparison__slider" data-image-comparison-slider="">
      <span #thumb class="image-comparison__thumb"
            data-image-comparison-thumb="">
        <svg class="image-comparison__thumb-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="10"
             viewBox="0 0 18 10" fill="#4bc6e5">
          <path class="image-comparison__thumb-icon--left"
                d="M12.121 4.703V.488c0-.302.384-.454.609-.24l4.42 4.214a.33.33 0 0 1 0 .481l-4.42 4.214c-.225.215-.609.063-.609-.24V4.703z"></path>
          <path class="image-comparison__thumb-icon--right"
                d="M5.879 4.703V.488c0-.302-.384-.454-.609-.24L.85 4.462a.33.33 0 0 0 0 .481l4.42 4.214c.225.215.609.063.609-.24V4.703z"></path>
        </svg>

      </span>
    </div>

    <div class="image-comparison__image-wrapper">
      <figure class="image-comparison__figure">
        <picture class="image-comparison__picture">
          <img
            [src]="comparisonItem.imgL.url"
            [alt]="comparisonItem.imgL.alt"
            class="image-comparison__image">
        </picture>

        <figcaption *ngIf="comparisonItem.imgL.label && showLabel"
                    class="image-comparison__caption image-comparison__caption--after">
          <span  class="image-comparison__caption-body">
            {{comparisonItem.imgL.label}}
          </span>
        </figcaption>
      </figure>
    </div>
  </div>
</div>