export class ProvinceRequest {
  clinics: boolean
  onlySliders: boolean
  private sort: 'DESC' | 'ASC';
  private orderBy: string;
  private idTratamientos: string;
  clinicaIER: boolean

  constructor() {
    this.clinics = false
    this.onlySliders = false
    this.sort = 'ASC'
    this.orderBy = ''
    this.idTratamientos = ''
    this.clinicaIER = false
  }

  public getClinics(): boolean {
    return this.clinics
  }

  public setClinics(value: boolean): void {
    this.clinics = value
  }

  public getOnlySliders(): boolean{
    return this.onlySliders
  }

  public setOnlySliders(value: boolean): void {
    this.onlySliders = value
  }

  public getSort(): 'DESC' | 'ASC' {
    return this.sort;
  }

  public setSort(value: 'DESC' | 'ASC') {
    this.sort = value
  }

  public getOrderBy(): string {
    return this.orderBy
  }

  public setOrderBy(value: string) {
    this.orderBy = value
  }

  public getIdTratamientos(): string {
    return this.idTratamientos
  }

  public setIdTratamientos(value: number[]) {
    this.idTratamientos = value.join(':')
  }

  public getClinicaIER(): boolean {
    return this.clinicaIER
  }

  public setClinicaIER(value: boolean): void {
    this.clinicaIER = value
  }
}
