import { Component, Input } from '@angular/core';
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-banner-promocion-primera-consulta-cabecera-mobile',
  templateUrl: './banner-promocion-primera-consulta-cabecera-mobile.component.html',
  styleUrls: ['./banner-promocion-primera-consulta-cabecera-mobile.component.css']
})
export class BannerPromocionPrimeraConsultaCabeceraMobileComponent {
  @Input() nrs!: any;
  public cdn: string;
  constructor() {
    this.cdn = environment.cdn;
   }
  toggleForm(event: Event): void {
    event.preventDefault();
    const itemSliderHomeForm = document.querySelector('.item__slider-home-form') as HTMLElement;
    const targetPosition = itemSliderHomeForm.getBoundingClientRect().top;
    const btnCita = document.querySelector('.btn-header-cita') as HTMLElement;
    const body = document.querySelector('body') as HTMLElement;

    // window.scrollTo({
    //   top: targetPosition,
    //   behavior: 'smooth',
    // });

    itemSliderHomeForm.classList.toggle('opened');
    btnCita.classList.toggle('opened');
    const modalFilterForm = document.querySelector('.modal__filter-form') as HTMLElement;
    if (window.innerWidth < 1100) {
      body.classList.toggle('modal__open');
    }
    modalFilterForm.style.display = modalFilterForm.style.display == 'none' ? 'block' : 'none';
  }

  showCondiciones(event: Event): void {
    event.preventDefault();
    const itemLegales = document.querySelector('.item__legales') as HTMLElement;
    const btnCodicionesMobile = document.querySelector('.condiciones-mobile') as HTMLElement;
    itemLegales.classList.toggle('active');
    btnCodicionesMobile.classList.toggle('active');   
  }
  
}
