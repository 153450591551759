import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IUnidad } from "../interfaces/unidad.interface";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UnidadService {
    private endpoint = 'unidad/';
    private domain: string | undefined;

    constructor(private http: HttpClient){
        this.domain = environment.domain;
    }

    getUnidades(): Observable<IUnidad[]> {
      return this.http.get<IUnidad[]>(`${this.domain}${this.endpoint}`)
    }
}
