<div gsAppear class="item__contenido-medico ">
    <img src="{{cdn}}imagenes/medicos/carlos-laria-xs.png" alt="">
    <div>   
    <p class="item__contenido-medico-autor">Este contenido ha sido elaborado por el <strong>Dr. Carlos Laria</strong></p>


    <p><strong>Oftalmólogo especializado en Oftalmología Pediátrica y Estrabismo. Director de la Unidad de Oftalmología Pediátrica y Estrabismo de Clínica Baviera.</strong></p>
    <br class="visible-lg">
    <p>El Dr. Carlos Laria cuenta con una amplia experiencia asistencial, quirúrgica, docente e investigadora en el ámbito de las patologías oculares en niños y en el ámbito del estrabismo, tanto en niños como en adultos. Ha realizado más de 340 publicaciones y comunicaciones en congresos y seminarios nacionales e internacionales y es autor de 14 libros, manuales y capítulos de libros nacionales e internacionales.</p>
    <!-- <a href="clinica-oftalmologica-medico/carlos-laria" class="item__contenido-medico-cv">Ver CV</a> -->
    </div>
</div>