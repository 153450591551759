import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {IDoctor} from "src/app/core/interfaces/doctor.interface";
import { IMedicoClinica } from "../interfaces/medicoClinica.interface";
import {AbstractService} from "./abstract.service";
import {DoctorRequest} from "../models/doctorRequest.model";
import {ITraining} from "../interfaces/training.interface";

@Injectable({
  providedIn: 'root'
})
export class DoctorService extends AbstractService {

  constructor(private http: HttpClient) {
    super('medico');
    this.paramNames = ['clinics']
  }

  getMedico(id: number): Observable<IDoctor> {
    return this.http.get<IDoctor>(`${this.baseUrl}/${id}`)
  }


  getMedicoPorClinica(id: any): Observable<IMedicoClinica[]> {
    return this.http.get<IMedicoClinica[]>(`${this.baseUrl}/clinica/${id}`)
  }

  getDoctorByField(fieldName: string,
                   fieldValue: string | null,
                   request: DoctorRequest): Observable<IDoctor> {

    const httpParams = this.buildParams(request);
    this.httpOptions = this.buildOptions(httpParams);

    {
      return this.http.get<IDoctor>(`${this.baseUrl}/${fieldName}/${fieldValue}`, this.httpOptions)
    }
  }

  getTrainings(idDoctor: number): Observable<ITraining[]> {
    return this.http.get<ITraining[]>(`${this.baseUrl}/${idDoctor}/formacion`)
  }

  getMedicoPorUnidadClinica(idUnidad: number, idClinica: number): Observable<IDoctor[]> {
    return this.http.get<IDoctor[]>(`${this.baseUrl}/unidadclinica/${idUnidad}/${idClinica}`)
  }

  getMedicoPorUnidad(idUnidad: number): Observable<IDoctor[]> {
    return this.http.get<IDoctor[]>(`${this.baseUrl}/unidadmedico/${idUnidad}`)
  }
}
