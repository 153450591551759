<div>
    <a class="item__faq-question"
       href="javascript:void(0)"
       #collapse
       (click)="onClick($event)">
      <svg [hidden]="showIcon"
           xmlns="http://www.w3.org/2000/svg"
           width="28"
           height="28"
           viewBox="0 0 28 28"
           fill="none">
        <circle cx="14" cy="14" r="14" fill="#00499A"/>
        <path
          d="M8.23937 13.0228L8.2389 13.0223C8.17828 12.9565 8.13093 12.8791 8.09898 12.7949C8.06715 12.7109 8.05109 12.6215 8.05147 12.5315M8.23937 13.0228L8.2389 12.0407C8.17828 12.1065 8.13093 12.1839 8.09898 12.2681C8.06715 12.352 8.05109 12.4415 8.05147 12.5315M8.23937 13.0228L12.248 17.3398C12.248 17.3398 12.248 17.3398 12.2481 17.3399C12.4912 17.6023 12.7806 17.8112 13.1003 17.9541C13.42 18.0969 13.7632 18.1707 14.1102 18.1707C14.4572 18.1707 14.8004 18.0969 15.1201 17.9541C15.4398 17.8112 15.7292 17.6023 15.9723 17.3399C15.9724 17.3398 15.9724 17.3398 15.9724 17.3398L19.981 13.0228C20.1039 12.8905 20.1709 12.7136 20.1709 12.5315C20.1709 12.3494 20.1039 12.1725 19.981 12.0402C19.8578 11.9074 19.6878 11.8302 19.5076 11.8302C19.3274 11.8302 19.1574 11.9074 19.0341 12.0402L15.0254 16.3572L8.23937 13.0228ZM8.05147 12.5315C8.05147 12.5312 8.05147 12.5309 8.05147 12.5306L8.22216 12.5315L8.05147 12.5324C8.05147 12.5321 8.05147 12.5318 8.05147 12.5315ZM9.18676 12.0407C9.18669 12.0406 9.18662 12.0405 9.18656 12.0405L13.195 16.3572L9.18676 12.0407ZM14.1102 16.7664C13.7696 16.7664 13.4402 16.6209 13.1951 16.3573L15.0253 16.3573C14.7802 16.6209 14.4508 16.7664 14.1102 16.7664Z"
          fill="white" stroke="white" stroke-width="0.341379"/>
      </svg>
      <h3>
        {{header}}
      </h3>
    </a>
    <div #toggle class="item-collapsed item__faq-answer">
      <ng-content></ng-content>
    </div>
  </div>