<div class="wrapper hidden-xs">
	<div class="item__banner-promo">
		<a #formOpen (click)="toggleForm($event)">
		<picture>
            <source srcset="{{cdn}}imagenes/promo/img-promo-desktop-persona.png"
                    media="(min-width: 768px)"
                    type="image/jpeg"
                    class=""
                    width="581"
                    height="">
            <img src="{{cdn}}imagenes/promo/img-promo-mobile-persona.png"
			width="111"
			height="141"
			loading="lazy"
                 alt="">
        </picture>
	</a>
	

		<div>
			<a #formOpen (click)="toggleForm($event)" class="item__title">
				<strong>1ª consulta <span>Gratis</span></strong>
			</a>
			<a #formOpen (click)="toggleForm($event)" class="item__subtitle">
				<strong>(Ahórrate 120&euro;)</strong>
            </a>
			<a href="#" #formOpen (click)="toggleForm($event)" class="btn-orange">Pedir cita sin compromiso</a>
			<p class="item__condiciones">Precio de la 1ª Consulta valorada en 120€. No acumulable a otras ofertas. Promoción válida hasta el 31/12/2025 para consultas preoperatorias de miopía, hipermetropía, astigmatismo, presbicia y cataratas (quedan excluidas consultas de otras especialidades). Pruebas incluidas. Promoción válida salvo errores tipográficos u ortográficos. Más info en <strong class="u-color-azul">www.clinicabaviera.com/promociones</strong>. {{nrs}}</p>

		</div>
    </div>

</div>
