import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IClinic } from '../../interfaces/clinic.interface';
import { IDoctor } from '../../interfaces/doctor.interface';
import { IUnidad } from '../../interfaces/unidad.interface';
import { IMedicoClinica } from '../../interfaces/medicoClinica.interface';
import { ClinicaService } from '../../services/clinica.service';
import { DoctorService } from '../../services/doctor.service';
import { UnidadService } from '../../services/unidad.service';
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-buscador-medico',
  templateUrl: './buscador-medico.component.html',
  styleUrls: ['./buscador-medico.component.css']
})
export class BuscadorMedicoComponent {
  @Input() personalizado!: boolean;
  @Input() id_unidad!: number;
  disabledSelectUnidad: boolean = true;
  formSelector!: FormGroup;
  clinicas: IClinic[] = [];
  medicosUnidadClinica: IDoctor[] = [];
  medicos: IDoctor[] = [];
  unidades: IUnidad[] = [];
  dobleFiltro:boolean = false;
  public cdn: string;
  
  constructor(private fb: FormBuilder, private _unidadService: UnidadService, private _medicoService: DoctorService, private _clinicaService: ClinicaService){
    this.cdn = environment.cdn;
  }

  ngOnInit() {
    this.disabledSelectUnidad = this.id_unidad > 0 ? true : false;
    this.id_unidad = this.id_unidad || 0;

    this.formSelector = this.fb.group({
      id_unidad:[{value: this.id_unidad, disabled: this.disabledSelectUnidad}],
      id_clinica:[0],
      id_medico:[0]
    });

    this._unidadService.getUnidades().subscribe((unidades: IUnidad[]) => {
      this.unidades = unidades;
    });

    this.sacarMedicos(this.id_unidad, 0, 0);
    if (this.id_unidad > 0){
      this.seleccionaUnidad(this.id_unidad);
      this.medicosUnidad(this.id_unidad);
      this.dobleFiltro = true;
    }
  }

  seleccionaUnidad(unidad: any){
    let id_unidad:number = typeof unidad === 'number' && !isNaN(unidad) ? unidad : unidad.target.value;
    this.id_unidad = id_unidad;
    this._clinicaService.getClinicasPorUnidad(id_unidad).subscribe((clinicasUnidad: IClinic[]) => {
      this.formSelector.controls['id_clinica'].setValue('0');
      this.clinicas = clinicasUnidad;
      this.formSelector.controls['id_medico'].setValue('0');
      if (!this.dobleFiltro){
        this.medicosUnidadClinica = [];
      }      
    });
  }

  seleccionaClinica(clinica: any){    
    let id_unidad: number = this.id_unidad;
    let id_clinica:number = clinica.target.value;
    if (id_clinica > 0){
      this.formSelector.controls['id_medico'].setValue('0');
      this.medicosUnidadClinica = [];
      this._medicoService.getMedicoPorUnidadClinica(id_unidad, id_clinica).subscribe((medicosUnidadClinida: IDoctor[]) => {
        this.medicosUnidadClinica = medicosUnidadClinida
      });
    } else {
      this.seleccionaUnidad(id_unidad);
    }    
  }

  seleccionaMedico(medico: any){
    if (this.dobleFiltro){
      let id_medico:number = medico.target.value;
      if (id_medico > 0){
        this._clinicaService.getClinicaPorMedico(id_medico).subscribe((clinicasUnidad: IClinic[]) => {
          this.clinicas = clinicasUnidad;
          this.formSelector.controls['id_clinica'].setValue(this.clinicas[0].id_clinica);
        });
      } else {
        this.seleccionaUnidad(this.id_unidad);
      }       
    }       
  }

  buscarMedicos(){
    let id_unidad: number = this.id_unidad;
    let id_clinica:number = this.formSelector.value.id_clinica;
    let id_medico:number = this.formSelector.value.id_medico;
    this.sacarMedicos(id_unidad, id_clinica, id_medico);
  }

  nl2br(text: string): string {
    return text.replace(/\n/g, '<br>');
  }

  medicosUnidad(id_unidad:any){
    this.medicosUnidadClinica = [];
    this._medicoService.getMedicoPorUnidad(id_unidad).subscribe((medicosUnidadClinida: IDoctor[]) => {
      this.medicosUnidadClinica = medicosUnidadClinida
    });
  }

  sacarMedicos(id_unidad:any, id_clinica:any, id_medico:any){
    const intlCollator = new Intl.Collator('es', { usage: "sort", caseFirst: "false" });
    this.medicos = [];
    if (id_medico > 0){
      this._medicoService.getMedico(id_medico).subscribe((medico: IDoctor) => {
        this.medicos.push(medico);
      });
    } else if (id_clinica > 0){
      this._medicoService.getMedicoPorUnidadClinica(id_unidad, id_clinica).subscribe((medicosUnidadClinida: IDoctor[]) => {
        this.medicos = medicosUnidadClinida
      });
    } else if (id_unidad > 0){
      this._clinicaService.getClinicasPorUnidad(id_unidad).subscribe((clinicasUnidad: IClinic[]) => {
        for (let i = 0; i < clinicasUnidad.length; i++) {

          this._medicoService.getMedicoPorUnidadClinica(id_unidad, clinicasUnidad[i].id_clinica!).subscribe({
            next: (medicoUnidadClinica: IDoctor[]) => {
              medicoUnidadClinica.forEach((medico: IDoctor) => {
                if (this.medicos.findIndex(x => x.id_medico == medico.id_medico) === -1) {
                    this.medicos.push(medico);
                }
              });
            },
            complete: () => { this.medicos.sort((a, b) => (intlCollator.compare(a.apellidos.concat(', ', a.nombre), b.apellidos.concat(', ', b.nombre)))) }
          });
        }
      });
    } else {
      this._clinicaService.getClinicas().subscribe((clinica: IClinic[]) => {
        for (let i = 0; i < clinica.length; i++) {
          this._medicoService.getMedicoPorClinica(clinica[i].id_clinica).subscribe({
            next: (medicoClinica: IMedicoClinica[]) => {
              let arrayMedicos: IDoctor[] = medicoClinica.reduce((medico, clinica) => {
                return medico.concat(clinica.medico);
              }, [] as IDoctor[]);
              arrayMedicos.forEach((medico: IDoctor) => {
                if (medico != null && (this.medicos.findIndex(x => x.id_medico == medico.id_medico) === -1)){
                  this.medicos.push(medico);
                }
              });
            },
            complete: () => { this.medicos.sort((a, b) => (intlCollator.compare(a.apellidos.concat(', ', a.nombre), b.apellidos.concat(', ', b.nombre)))) }
        });
        }
      });
    }
  }
}
