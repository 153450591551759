<section class="opiniones bg-opiniones ">
    <div class="wrapper">
        <h2 class="opiniones__title">Conoce las opiniones reales <strong>de nuestros pacientes</strong></h2>


        <div class="opiniones__box-puntuacion">
            <div class="opiniones__box-logo">
                <img src="{{cdn}}imagenes/icons/icon-award.svg" alt="">
                <img src="{{cdn}}imagenes/logo-google.svg" alt="">
            </div>
            <div class="opiniones__box-resultado">
                <div class="opiniones__stars">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                </div>
                <div class="opiniones__puntuacion">
                    <p><strong>4,8</strong>/5</p>

                </div>
            </div> <!-- end opiniones__box-resultado -->


         </div><!-- end opiniones__box-puntuacion -->

         <div class="opiniones__items">
            <div class="opiniones__items-slider">
                <ngx-slick-carousel [config]="slideConfig">

                    <div ngxSlickItem>
                        <div class="opiniones__items-card">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE

                                </p>
                                <p class="opiniones__items-text-nombre">
                                    Laura Laura
                                </p>
                                <p class="opiniones__items-text-date">Mayo de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Fenomenal como siempre, la profesionalidad y tacto con los niños de 100%, gracias</p>


                            </div>


                         </div><!--  end opiniones__items-card -->
                </div>

                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Ana Capellino
                            </p>
                            <p class="opiniones__items-text-date">Abril de 2023</p>
                            <p class="opiniones__items-text-quote">
                                Gran equipo profesional y muy humanos. Vamos toda la familia a las revisiones y siempre nos atienden muy bien. Además, fui un día con mi hija pequeña por una urgencia y no dudaron en atenderla. Es destacable el increíble trato y paciencia que tuvieron con ella para solucionarle el  problema.</p>

                        </div>


                     </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
                <div class="opiniones__items-card">
                    <div class="opiniones__stars">
                        <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                    </div>
                    <div class="opiniones__items-text">
                        <p class="opiniones__items-text-paciente">
                            PACIENTE

                        </p>
                        <p class="opiniones__items-text-nombre">
                            Paula Peña López
                        </p>
                        <p class="opiniones__items-text-date">Marzo de 2023</p>
                        <p class="opiniones__items-text-quote">
                            Ayer estuve con mi hija de 1 año. Gracias a todo el equipo por el trato cercano y humano, pero sobre todo por la profesionalidad de la doctora Alarcón. Sin duda recomiendo la clínica.</p>
                    </div>
                 </div><!--  end opiniones__items-card -->
        </div>
        <div ngxSlickItem>
            <div class="opiniones__items-card">
                <div class="opiniones__stars">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                    <p class="opiniones__items-text-paciente">
                        PACIENTE

                    </p>
                    <p class="opiniones__items-text-nombre">
                        Celia Maño Bosch
                    </p>
                    <p class="opiniones__items-text-date">Febrero de 2023</p>
                    <p class="opiniones__items-text-quote">
                        Son muy amables y atentas, sobre todo con niños, tienen mucha paciencia mi hija ha salido muy contenta.</p>
                </div>
             </div><!--  end opiniones__items-card -->
    </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE

                                </p>
                                <p class="opiniones__items-text-nombre">
                                    Nicole Aguirre Puchades
                                </p>
                                <p class="opiniones__items-text-date">Diciembre de 2022</p>
                                <p class="opiniones__items-text-quote">
                                    Súper encantada. Me atendieron  por mi hijo pequeño  sin tener cita por una urgencia. Y son un encanto. Un 10 para el oftalmólogo Jaime. Gracias al equipo!!</p>


                            </div>


                         </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Laura Laura
                            </p>
                            <p class="opiniones__items-text-date">Diciembre de 2022</p>
                            <p class="opiniones__items-text-quote">
                                ATENCIÓN DE PROFESIONALES DE 100 Y TACTO CON NIÑOS MEJOR IMPOSIBLE, GRACIAS CLINICA BAVIERA
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Noelia Ortiz
                            </p>
                            <p class="opiniones__items-text-date">Noviembre de 2022</p>
                            <p class="opiniones__items-text-quote">
                                Trato y atención excelente! He ido a hacerles una revisión a mis hijos y he salido encantada, muy recomendable!
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Dany Skrypnik
                            </p>
                            <p class="opiniones__items-text-date">Septiembre de 2022</p>
                            <p class="opiniones__items-text-quote">
                                Excelente atención y sobre todo el cariño con que tratan a los pequeños. <br/>
                                Sin dudas lo recomendamos.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                María José Aledo Martínez
                            </p>
                            <p class="opiniones__items-text-date">Febrero de 2022</p>
                            <p class="opiniones__items-text-quote">
                                Atención excelente, sin duda un acierto desde la recepción hasta el médico. Como madre, lo recomiendo con los ojos cerrados
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                María Cristina Cajamarca Alvarado
                            </p>
                            <p class="opiniones__items-text-date">Enero de 2022</p>
                            <p class="opiniones__items-text-quote">
                                Servicio de 10, han sido todas encantadoras y el trato con los niños espectacular, gracias.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Isabel Blanco Glez 
                            </p>
                            <p class="opiniones__items-text-date">Agosto de 2021</p>
                            <p class="opiniones__items-text-quote">
                                En la clínica nos han ayudado muchísimo con un problema de mi hijo (1 año). La doctora es totalmente respetuosa con mi pequeño, haciéndolo todo al ritmo que él necesita, y hablándole con suavidad. Absolutamente encantadoras y tremendamente amables conmigo y mi bebé, todo el personal de 10, igual que en la de Oviedo.
Sin duda, Baviera es ya mi clínica de oftalmología de referencia.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Aroa Ruiz
                            </p>
                            <p class="opiniones__items-text-date">Diciembre de 2020</p>
                            <p class="opiniones__items-text-quote">
                                He llevado a mi hijo de 5 años y lo han mirado muy bien. Exploración completa. La Dra Camargo muy atenta y amable con el peque.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Pilar Marron
                            </p>
                            <p class="opiniones__items-text-date">Mayo de 2020</p>
                            <p class="opiniones__items-text-quote">
                                Trato excelente por parte de todo el personal. El Dr. Antonio Cañadillas tiene paciencia y mano con los niños, ha sido un acierto ir a esta clínica.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                MAR BS
                            </p>
                            <p class="opiniones__items-text-date">Diciembre de 2019</p>
                            <p class="opiniones__items-text-quote">
                                Un trato excelente. Amables, educados, encantadores y con una paciencia infinita. Fui con mi hija pequeña y la niña esta encantada.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>



            </ngx-slick-carousel>
            
            </div><!--  end opiniones__items-slider -->

          </div><!-- end opiniones__items -->

    </div><!--  end wrapper -->

</section>