import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})

export class IpService {

  constructor(private http: HttpClient) {
  }

  public getIp(): Observable<any> {
    return this.http.get<any>(environment.ipURl)
  }

}
