import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HeaderFormComponent} from "./components/header-form/header-form.component";
import {ModalComponent} from "./lib/ui/modal/modal.component";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {MapFinderComponent} from "./components/map-finder/map-finder.component";
import {GsAppearDirective} from "./directives/gs-appear.directive";
import {RouterModule} from "@angular/router";
import {RefractivaOpinionesComponent} from './components/refractiva-opiniones/refractiva-opiniones.component';
import {BoxMedicoFernandoLlovetComponent} from './components/box-medico-fernando-llovet/box-medico-fernando-llovet.component';
import {CollapseComponent} from "./lib/ui/collapse/collapse.component";
import {BotonPedirCitaComponent} from './components/boton-pedir-cita/boton-pedir-cita.component';
import {CataratasOpinionesComponent} from './components/cataratas-opiniones/cataratas-opiniones.component';
import {NewsLetterFormComponent} from './components/news-letter-form/news-letter-form.component';
import {ImageComparisonComponent} from "./lib/ui/image-comparison/image-comparison.component";
import {BoxMedicoCarlosLariaComponent} from './components/box-medico-carlos-laria/box-medico-carlos-laria.component';
import {BoxMedicoMartaFigueroaComponent} from './components/box-medico-marta-figueroa/box-medico-marta-figueroa.component';
import {BoxMedicoGonzaloMunozComponent} from './components/box-medico-gonzalo-munoz/box-medico-gonzalo-munoz.component';
import {NuestrosMedicosRetinaComponent} from './components/nuestros-medicos-retina/nuestros-medicos-retina.component';
import {CalculadoraComponent} from './components/calculadora/calculadora.component';
import {BannerPromocionPrimeraConsultaCabeceraMobileComponent} from './components/banner-promocion-primera-consulta-cabecera-mobile/banner-promocion-primera-consulta-cabecera-mobile.component';
import {BannerPromocionPrimeraConsultaNewComponent} from './components/banner-promocion-primera-consulta-new/banner-promocion-primera-consulta-new.component';
import {OtrosTratamientosOpinionesComponent} from './components/otros-tratamientos-opiniones/otros-tratamientos-opiniones.component';
import { PresbiciaOpinionesComponent } from './components/presbicia-opiniones/presbicia-opiniones.component';
import { BannerPresbiciaPrimeraConsultaNewComponent } from './components/banner-presbicia-primera-consulta-new/banner-presbicia-primera-consulta-new.component';
import { BannerPresbiciaPrimeraConsultaMobileComponent } from './components/banner-presbicia-primera-consulta-mobile/banner-presbicia-primera-consulta-mobile.component';
import {GoogleMapsModule} from "@angular/google-maps";
import {BuscadorMedicoComponent} from "./components/buscador-medico/buscador-medico.component";
import {MapFinderRetinaComponent} from "./components/map-finder-retina/map-finder-retina.component";
import {RetinaOpinionesComponent} from "./components/retina-opiniones/retina-opiniones.component";
import { ClinicasPorProvinciaComponent } from './components/clinicas-por-provincia/clinicas-por-provincia.component';
import { FormInternacionalComponent } from './components/form-internacional/form-internacional.component';
import {E404Component} from "./components/error/e404/e404.component";
import {NgxUsefulSwiperModule} from "ngx-useful-swiper";
import {NgxPaginationModule} from "ngx-pagination";
import { BannerCataratasPrimeraConsultaComponent } from './components/banner-cataratas-primera-consulta/banner-cataratas-primera-consulta.component';
import { BannerCataratasPrimeraConsultaMobileComponent } from './components/banner-cataratas-primera-consulta-mobile/banner-cataratas-primera-consulta-mobile.component';

@NgModule({
  declarations: [
    HeaderFormComponent,
    MapFinderComponent,
    RefractivaOpinionesComponent,
    BoxMedicoFernandoLlovetComponent,
    BoxMedicoCarlosLariaComponent,
    BotonPedirCitaComponent,
    CataratasOpinionesComponent,
    NewsLetterFormComponent,
    ImageComparisonComponent,
    BoxMedicoMartaFigueroaComponent,
    NuestrosMedicosRetinaComponent,
    BoxMedicoGonzaloMunozComponent,
    CalculadoraComponent,
    BannerPromocionPrimeraConsultaCabeceraMobileComponent,
    BannerPromocionPrimeraConsultaNewComponent,
    OtrosTratamientosOpinionesComponent,
    PresbiciaOpinionesComponent,
    BannerPresbiciaPrimeraConsultaNewComponent,
    BannerPresbiciaPrimeraConsultaMobileComponent,
    BuscadorMedicoComponent,
    MapFinderRetinaComponent,
    RetinaOpinionesComponent,
    ClinicasPorProvinciaComponent,
    FormInternacionalComponent,
    E404Component,
    BannerCataratasPrimeraConsultaComponent,
    BannerCataratasPrimeraConsultaMobileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ModalComponent,
    SlickCarouselModule,
    ReactiveFormsModule,
    GsAppearDirective,
    NgOptimizedImage,
    RouterModule,
    CollapseComponent
  ],

  exports: [
    BuscadorMedicoComponent,
    ModalComponent,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    MapFinderComponent,
    HeaderFormComponent,
    GsAppearDirective,
    NgOptimizedImage,
    RefractivaOpinionesComponent,
    BoxMedicoFernandoLlovetComponent,
    BoxMedicoCarlosLariaComponent,
    BoxMedicoMartaFigueroaComponent,
    BoxMedicoGonzaloMunozComponent,
    CollapseComponent,
    BotonPedirCitaComponent,
    CataratasOpinionesComponent,
    NewsLetterFormComponent,
    ImageComparisonComponent,
    NuestrosMedicosRetinaComponent,
    CalculadoraComponent,
    BannerPromocionPrimeraConsultaCabeceraMobileComponent,
    BannerPromocionPrimeraConsultaNewComponent,
    OtrosTratamientosOpinionesComponent,
    PresbiciaOpinionesComponent,
    BannerPresbiciaPrimeraConsultaNewComponent,
    BannerPresbiciaPrimeraConsultaMobileComponent,
    GoogleMapsModule,
    MapFinderRetinaComponent,
    RetinaOpinionesComponent,
    ClinicasPorProvinciaComponent,
    FormInternacionalComponent,
    NgxUsefulSwiperModule,
    NgxPaginationModule,
    BannerCataratasPrimeraConsultaComponent,
    BannerCataratasPrimeraConsultaMobileComponent,
  ]
})
export class CoreModule { }
