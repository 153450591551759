<div gsAppear class="item__contenido-medico ">
    <img ngSrc="{{cdn}}imagenes/medicos/img-marta-s-figeroa.png" alt="" width="102" height="102" loading="lazy">
    <div>   
    <p class="item__contenido-medico-autor">Este contenido ha sido elaborado por la <strong>Dra. Marta S. Figueroa</strong></p>
    <p><strong>Oftalmóloga especializada en Retina Quirúrgica y Retina Médica. Directora de la Unidad de Retina y del Área de I+D de Retina de Clínica Baviera. Jefa del Departamento de Retina del Hospital Universitario Ramón y Cajal de Madrid.</strong></p>
    <br class="visible-lg">
    <p>La Dra. S. Figueroa cuenta con una amplia experiencia asistencial, quirúrgica, investigadora y docente en el ámbito de las patologías de retina, especialmente en lo que respecta al tratamiento de los desprendimientos de retina, la retinopatía diabética, los traumatismos oculares, la cirugía macular y la DMAE. Ha publicado más de 150 artículos científicos en revistas nacionales e internacionales y 12 libros Es Profesora Asociada en la Universidad de Alcaláde Henares desde 1991.</p>
    <a href="clinica-oftalmologica-medico/marta-figueroa" class="item__contenido-medico-cv">Ver CV</a>
    </div>
</div>
