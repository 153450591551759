import {Component, Input, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { IpService } from "../../services/ip.service";
import { InternationalFormService } from "../../services/formInternacional.service";
import { IFormInternacional } from "../../interfaces/formInternacional.interface";
import { sendgridService } from "../../services/sendgrid.service";

@Component({
  selector: 'app-form-internacional',
  templateUrl: './form-internacional.component.html',
})

export class FormInternacionalComponent implements OnInit {
  submitted = false;
  visible = false;
  form: FormGroup;
  ip: any;
  @Input() origenForm!: any;

  constructor(private sendgridService: sendgridService ,private ipService: IpService, private fb: FormBuilder, private internacionalFormService:InternationalFormService) {
    this.form = this.buildForm()
  }

  ngOnInit(): void {
    this.getIp()
  }

  private getIp() {
    this.ipService.getIp().subscribe({
      next: r => {
        this.ip = r.ip;
      }
    })
  }

  private buildForm(): FormGroup {
    return this.fb.group({
      nombre: ['', Validators.required],
      telefono: ['', [Validators.required,  Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9)]
      ],
      email: ['', [Validators.required, Validators.email]],
      clinica: ['', Validators.required],
      ciudad: ['', Validators.required],
      nacionalidad: ['', Validators.required],
      observaciones: ['', Validators.required],
      acepto: [false, Validators.requiredTrue],
      IP: 'none'
    });
  }

  onSubmit() {
    this.submitted = true;
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.valid) {
      this.form.value.publicidad = this.form.value.publicidad ? 'S' : 'N';
      this.form.value.IP = this.ip;    
      this.form.value.pagina_origen = this.origenForm;
      const data: IFormInternacional = this.form.value
      console.log(this.form.value)
      this.internacionalFormService.sendSolicitud(data).subscribe({
          next: () => {
            this.visible = true;
            this.sendEmail();
          }, error: err => {
            console.log(err);
          }
        }
      )
    }
  }

  private sendEmail() {
    const fecha = this.getFecha();
    const publicidad = this.form.value.publicidad == 'S' ? 'Si' : 'No';
    const data = {
      to: ['carlos.sierra@marketinet.com', 'jose.jimenez@marketinet.com', 'mariana.perez@marketinet.com', 'mmataix@clinicabaviera.com', 'ecalle@clinicabaviera.com'],
      asunto: `Formulario pacientes ${this.origenForm}`,
      html: `Formulario pacientes ${this.origenForm}:
Nombre y Apellidos: ${this.form.value.nombre} 
Ciudad: ${this.form.value.ciudad}
Email: ${this.form.value.email}
Teléfono: ${this.form.value.telefono}
Nacionalidad: ${this.form.value.nacionalidad}
Clínica: ${this.form.value.clinica}
Observaciones: ${this.form.value.observaciones}
Página origen: ${this.origenForm}
Publicidad: ${publicidad}
Fecha: ${fecha}`
    };

    this.sendgridService.sendEmail(data).subscribe({
      next: r => {
        console.log(r)
      }, error: err => {
        console.log(err)
      }, complete: () => {
      }
    })
  }

  private getFecha(){
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses comienzan desde 0
    const year = date.getFullYear();

    // Formatear la fecha en el formato d/m/Y
    return `${day}/${month}/${year}`;
  }

  public isNotValid(control: string): boolean {
    return !this.form.controls[control].valid && this.submitted;
  }

  public onlyNumbers(event: any){
    const charCode = (event.which)?event.which: event.code;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }
}
