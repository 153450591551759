import {Injectable} from "@angular/core";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {RequestType} from "../utils/types/request.type";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export abstract class AbstractService {
  protected paramNames: string[] = []
  protected endpoint: string

  protected httpOptions: object;
  protected httpHeaders: HttpHeaders;

  protected constructor(endpoint: string) {
    this.httpHeaders = <HttpHeaders>{}
    this.httpOptions = {};
    this.endpoint = endpoint
  }

  protected buildParams(request: RequestType): HttpParams {
    let result = new HttpParams();

    this.paramNames.forEach(param => {
      const getter = this.buildGetter(param);
      const value = request[getter]();

      if (value) {
        result = result.set(param, String(value));
      }
    });

    return result;
  }

  protected buildGetter(name: string): string {
    return 'get' + name.charAt(0).toUpperCase() + name.substring(1);
  }

  protected buildOptions(httpParams?: HttpParams | Object, excludedHeader?: string): object {
    let headers = this.httpHeaders;

    if (excludedHeader) {
      headers = headers.delete(excludedHeader);
    }

    return {
      headers,
      params: httpParams || {},
    };
  }

  protected get baseUrl(): string {
    return environment.domain.concat(this.endpoint)
  }



}
