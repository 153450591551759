<section class="opiniones bg-retina">
    <div class="wrapper">
        <h2 class="opiniones__title">Conoce las opiniones reales <strong>de nuestros pacientes</strong></h2>


        <div class="opiniones__box-puntuacion">
            <div class="opiniones__box-logo">
                <img src="{{cdn}}imagenes/icons/icon-award.svg" alt="">
                <img src="{{cdn}}imagenes/logo-google.svg" alt="">
            </div>
            <div class="opiniones__box-resultado">
                <div class="opiniones__stars">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                </div>
                <div class="opiniones__puntuacion">
                    <p><strong>4,8</strong>/5</p>

                </div>
            </div> <!-- end opiniones__box-resultado -->


         </div><!-- end opiniones__box-puntuacion -->

         <div class="opiniones__items">
            <div class="opiniones__items-slider">
                <ngx-slick-carousel [config]="slideConfig">

                    <div ngxSlickItem>
                        <div class="opiniones__items-card">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE
    
                                </p>
                                <p class="opiniones__items-text-nombre">
                                    Javier Huarte
                                </p>
                                <p class="opiniones__items-text-date">Julio de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Me operaron de cataratas y posteriormente de retina, me puse en manos del Dr. Luis Arrevola, la cirugía me fue muy bien, pero que muy bien, sin dolor, sin efectos secundarios, todo un éxito, y hoy me ha tratado el otro ojo mediante laser pues tenía la retina dañada, pues ahora, unas horas después, me estoy tomando unas cervezas, increíble, gracias Doctor, eres totalmente un Crack.
                                </p>
    
    
                            </div>
    
    
                         </div><!--  end opiniones__items-card -->
                    </div>

                    <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Aitor Moliner
                            </p>
                            <p class="opiniones__items-text-date">Enero de 2023</p>
                            <p class="opiniones__items-text-quote">
                                Mi experiencia en esta clínica es muy buena, se la recomiendo a todos. Tienen un equipo personal muy educado y eficaz desde la recepción hasta el quirofano, te sientes en todo momento arropado.
En 2013 tuve un accidente en el cual un trozo de metal se introdujo dentro del ojo, alojándose muy cerca del nervio óptico.
Aquí me operaron y fue todo bien, pero en 2018 tuve un desprendimiento de retina y no lo dude volví a operarme con ellos.
Ahora me acaban de volver a operar, ya que quería mejorar la visión y estoy muy contento por los resultados obtenidos.
Estoy muy agradecido al doctor Salaberria que es el que me operó las dos primeras veces y al doctor Ramón Jiménez por operarme esta última, tener tanta paciencia y ser como es.</p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Pilar Iribertegui
                            </p>
                            <p class="opiniones__items-text-date">Diciembre de 2022</p>
                            <p class="opiniones__items-text-quote">
                                Hoy he estado en consulta de retina con la DRA. Capote. Todo excelente. Y también comentar el trabajo y trato de enfermeras y auxiliares. Sobresaliente
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Cristina Guirao
                            </p>
                            <p class="opiniones__items-text-date">Octubre de 2022</p>
                            <p class="opiniones__items-text-quote">
                               Inmejorable experiencia en la Clínica Baviera de Alicante. Gracias infinitas al Dr. Javaloy por su rapida intervención y por ponernos en manos del Dr. Casas, quien con enorme pericia  ha resuelto con éxito un caso delicado.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Alex De Weerdt
                            </p>
                            <p class="opiniones__items-text-date">Enero de 2022</p>
                            <p class="opiniones__items-text-quote">
                                Cirugía profesional de mi desprendimiento de retina con un resultado maravilloso. Equipo superior y gracias por el cuidado.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-grey-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Sandra López
                            </p>
                            <p class="opiniones__items-text-date">Noviembre de 2022</p>
                            <p class="opiniones__items-text-quote">
                                No marco 5 estrellas porque no existe la perfeccion. Muy recomendable! Doctor Salaberria y equipo, fantásticos. Mila esker
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Chelo Perdices
                            </p>
                            <p class="opiniones__items-text-date">Septiembre de 2021</p>
                            <p class="opiniones__items-text-quote">
                                Grandes profesionales eternamente agradecida al doctor Guillermo pues salvo el ojo de mi madre que tenía un grave desprendimiento de retina . No hay palabras para agracer tanta sabiduría profesionalidad y trato humano.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Oscar Bayona
                            </p>
                            <p class="opiniones__items-text-date">Agosto de 2020</p>
                            <p class="opiniones__items-text-quote">
                                Para mi (como miope magno) con lente fáquica y otra icl baviera ha sido todo. He tenido muchos problemas con la vista desde pequeño y los diferentes médicos que me han atendido han sido muy profesionales y a la vez cercanos (eso es muy importante) para mi. Yo he de reconocer que los he utilizado mucho porque en la tarjeta sanitaria de mapfre que me paga mi empresa entra baviera y todo ha sido siempre fácil. La retinóloga siempre ha sido un sol conmigo y me ha calmado siempre mis miedos. Gracias a baviera y a la oftalmología tan necesaria en un mundo digital.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
            </ngx-slick-carousel>
            </div><!--  end opiniones__items-slider -->
          </div><!-- end opiniones__items -->
    </div><!--  end wrapper -->
</section>