import { Component } from '@angular/core';
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-nuestros-medicos-retina',
  templateUrl: './nuestros-medicos-retina.component.html',
  styleUrls: ['./nuestros-medicos-retina.component.css']
})
export class NuestrosMedicosRetinaComponent {
  public cdn: string;
  constructor() {
    this.cdn = environment.cdn;
   }
}
