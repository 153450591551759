<section class="item__newsletter">
    <div class="wrapper">
        <div class="item__newsletter-title">
            <img ngSrc="{{cdn}}imagenes/icons/icon-newsletter-mobile.svg" alt="" class="gs_appear_up" width="100" height="86" loading="lazy">
            <p class="title__h2 gs_appear_up">Recibe información sobre nuestras promociones</p>
        </div>
        <!-- end item__newsletter-title -->

            <form class="item__newsletter-form gs_appear_up" [formGroup]="forms" action="" (ngSubmit)="altaNewsletter()">
                <div [hidden]="!visible" class="form-thanks">
                    <p class="big text-left">
                      Hemos recibido tu solicitud. En breve nos pondremos en contacto contigo.
                    </p>
                    <p class="big text-left">
                      <span>Gracias</span>
                      <br/>
                      <small>por confiar en Clínica Baviera.</small>
                    </p>
                  </div>
                <div [hidden]="visible">
                    <input type="text" formControlName="nombre" placeholder="Nombre">
                    <small *ngIf="isNotValid('nombre')">
                        Rellene el nombre.
                    </small>
                    <input type="text" formControlName="apellidos" placeholder="Apellidos">
                    <small *ngIf="isNotValid('apellidos')">
                        Rellene los apellidos.
                    </small>
                    <input type="text" formControlName="email" placeholder="Correo electrónico">
                    <small *ngIf="isNotValid('email')">
                        Rellene el email.
                    </small>
                    <div class="item__form-check item-black" >
                        <div class="item__form-check-checkbox">
                            <input name="acepto" formControlName="acepto" type="checkbox" id="check-n44"> <label for="check-n44"><span class="label-icon"></span> </label>
                        </div>
                        <span class="item__form-check-label">He le&iacute;do y acepto la <a href="https://www.clinicabaviera.com/condiciones" target="_blank">Pol&iacute;tica de Privacidad</a></span>
                        <!--checkbox-->
                        <small *ngIf="isNotValid('acepto')">
                            Acepta la política de privacidad.
                          </small>
                </div>
                    <button type="submit" class="btn-orange">Suscríbete</button>
                </div>
            </form>


    </div>
    <!-- end wrapper -->
</section>
