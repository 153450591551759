import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ISolicitudInfoPromo } from "src/app/core/interfaces/solicitudInfoPromo.interface";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class SolicitudInfoPromoService {
    private readonly endpoint = 'solicitudinfopromo';

    constructor(private http: HttpClient) {
  
    }
  
    public sendSolicitud(request: ISolicitudInfoPromo): Observable<any> {
      return this.http.post<any>(this.baseUrl, request);
    }
  
    public updateSolicitud(id: number, request: object): Observable<any> {
      return this.http.put<any>(`${this.baseUrl}/${id}`, request);
    }
  
    private get baseUrl(): string {
      return environment.domain.concat(this.endpoint)
    }
}
  
