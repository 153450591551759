import { Component } from '@angular/core';
import { SLIDE_CONFIG } from 'src/app/core/utils/const/slideConfig.const';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-presbicia-opiniones',
  templateUrl: './presbicia-opiniones.component.html',
  styleUrls: ['./presbicia-opiniones.component.css']
})
export class PresbiciaOpinionesComponent {
  public readonly slideConfig: object
  public cdn: string
  constructor() {
    this.slideConfig = SLIDE_CONFIG.precioFinanciacionOperacionPresbiciaComponent;
    this.cdn = environment.cdn;
   }
}
