<div class="item__slider-home-form-title">
  <img ngSrc="{{cdn}}imagenes/icons/icon-phone-form.svg" alt="" height="68" width="69">
  <p>Ll&aacute;manos gratis<br><span><a href="tel:900180100" class="u-color-blanco">{{ telefono }}</a></span></p>
  <p><strong>¿O prefieres que te llamemos?</strong></p>
  <span #formMobileClose (click)="closeFilter($event, formMobileClose)" class="close">
          <img src="{{cdn}}imagenes/icons/icon-close-round-blue.png" alt="">
        </span>
</div>

<form [formGroup]="form" (ngSubmit)="submitForm()">

  <div [hidden]="!visible" class="form-thanks">
    <p class="big text-left">
      Hemos recibido tu solicitud. En breve nos pondremos en contacto contigo.
    </p>
    <p class="big text-left">
      <span>Gracias</span>
      <br/>
      <small>por confiar en Clínica Baviera.</small>
    </p>
  </div>

  <div [hidden]="visible">

    <input type="text"
           placeholder="Nombre y apellidos"
           formControlName="nombre"
           [class.is-invalid]="isNotValid('nombre')">

    <small *ngIf="isNotValid('nombre')">
      Rellene el nombre y apellidos.
    </small>

    <input type="text"
           (keypress)="onlyNumbers($event)"
           placeholder="Tel&eacute;fono"
           formControlName="telefono"
           maxlength="9"
           [class.is-invalid]="isNotValid('telefono')"
    >
    <small *ngIf="isNotValid('telefono')">
      Teléfono invalido.
    </small>

    <input type="email"
           placeholder="Email"
           formControlName="email"
           [class.is-invalid]="isNotValid('email')" class="hidden-xs">

    <small *ngIf="isNotValid('email')">
      Email invalido.
    </small>

    <div class="item__form-check">
      <div class="item__form-check-checkbox">
        <input name="acepto" formControlName="acepto" type="checkbox" id="check-03"> <label for="check-03"><span
        class="label-icon"></span> </label>
      </div>
      <span class="item__form-check-label">
        He le&iacute;do y acepto la
        <a href="./condiciones" target="_blank">
          Pol&iacute;­tica de Privacidad
        </a>
      </span>
      <!--checkbox-->
      <small *ngIf="isNotValid('acepto')">
        Acepta la política de privacidad.
      </small>
    </div>

    <div class="item__form-check">
      <div class="item__form-check-checkbox">
        <input name="aceptoPublicidad" formControlName="aceptoPublicidad" type="checkbox" id="check-02"> <label
        for="check-02"><span class="label-icon"></span> </label>
      </div>
      <span class="item__form-check-label">Acepto recibir informaci&oacute;n comercial</span>
      <!--checkbox-->
    </div>

    <button type="submit" class="btn-orange">Quiero que me llamen</button>
  </div>
</form>
<!-- end form -->
