import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SolicitudInfoPromoService} from "../../services/solicitudInfoPromo.service";
import { ISolicitudInfoPromo } from "../../interfaces/solicitudInfoPromo.interface";
import { SalesforceService } from '../../services/salesforce.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-news-letter-form',
  templateUrl: './news-letter-form.component.html',
  styleUrls: ['./news-letter-form.component.css']
})
export class NewsLetterFormComponent {
  forms: FormGroup;
  submitted = false;
  visible = false;
  public cdn: string;
  
  constructor(private salesforceService: SalesforceService, private fb:FormBuilder, private solicitudInfoPromoService: SolicitudInfoPromoService){
    this.cdn = environment.cdn;
    this.forms = this.fb.group({
      nombre:['', Validators.required],
      apellidos:['', Validators.required],
      email:['', [Validators.required, Validators.email]],
      acepto: [false, Validators.requiredTrue]
    });
  }

  altaNewsletter(){
    this.submitted = true;
    if (this.forms.valid) {   
      this.solicitudInfoPromoService.sendSolicitud(this.forms.value).subscribe({
        next: () => {
          this.visible = true;
          this.salesforceService.sendNewsletter(this.forms.value).subscribe()
        }
      });  
    } else {
      Object.values(this.forms.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  public isNotValid(control: string): boolean {
    return !this.forms.controls[control].valid && this.submitted;
  }
}
