<section>
    <div class="wrapper">
        <div class="item__banner-retina">
            <div>
                <h2 class="item__title"> Nuestros<strong> médicos </strong> </h2>
                <a href="oftalmologos-unidad-de-retina" class="btn-dark-blue btn-default">Ver médicos especialistas en retina</a>

            </div>

            <picture>
                <source srcset="{{cdn}}imagenes/retina/img-banner-retina.png"
                        media="(min-width: 768px)"
                        type="image/jpeg"
                        class=""
                        width="581"
                        height="">
                <img src="{{cdn}}imagenes/retina/img-banner-retina-mobile.png"
                     alt="Dra. Marta Suárez Figueroa">
            </picture>

           
            
        </div>
        
    </div>
    
</section> 

