import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class GoogleMapsService {

  private isScriptLoaded = false;

  loadScript(): Promise<void> {
    return new Promise((resolve, reject) =>  {
      if (this.isScriptLoaded) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://maps.google.com/maps/api/js?key=AIzaSyAvJsdgTKoDP2vzxf3QYWmouba-Whs_bSA';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.isScriptLoaded = true;
        resolve();
      };
      script.onerror = () => {
        reject('Error loading Google Maps script');
      };
      document.head.appendChild(script);
    });
  }
}
