import { Component, OnInit, ViewChild } from '@angular/core';
import * as $ from "jquery";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import {APIPacienteService} from "../../core/services/apiPaciente.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  @ViewChild('dropdown') dropdownMenu!: HTMLUListElement;
  public form!: FormGroup
  public loading = false
  submitted = false;
  error = false;
  public cdn: string;
  constructor(private fb: FormBuilder,
              private apiPacienteService: APIPacienteService,
              private router: Router) {
                this.cdn = environment.cdn;
              }

  ngOnInit(): void {
    //this.addEventListeners()
    this.initForm()
  }

  public initForm() {
    this.form = this.fb.group({
      User: ['', Validators.required],
      Password: ['', Validators.required],
    });
  }

  openMenu(event: Event, menu: HTMLAnchorElement): void {
    event.preventDefault();
    $(menu)
      .parents('.item__top-bar')
      .siblings('.item__mobile-menu')
      .toggleClass('toggled');
  }

  closeMenu(event: Event, menuClose: HTMLAnchorElement): void {
    event.preventDefault();
    $(this.dropdownMenu.innerHTML).slideUp();
    $(menuClose).parents('.item__mobile-menu').toggleClass('toggled');
  }

  openMenudkt(event: Event, menu: HTMLAnchorElement): void {
    event.preventDefault();
    $(menu)
      .parents('.item__top-bar')
      .siblings('.item__menu-desktop')
      .toggleClass('toggled');
    // $(menu)
    //   .children('.abrir').addClass('u-hidden')
    //   .children('.cerrar').removeClass('u-hidden')
  }


// Función para ocultar menú una vez que se haga click en un enlace en desktop

  toggleMenuInternoDkt(event: Event): void {
    event.preventDefault();
    $('.item__menu-desktop').toggleClass('toggled');
  }



  TogglePatientArea(event: Event, elementButton: HTMLAnchorElement): void {
    event.preventDefault();
    $('.box-parient-area-menu').toggleClass('active');
    setTimeout(function () {
      $('.patient-area-menu').removeClass('hidden');
      $('.patient-area-menu').toggleClass('active');
    }, 500);
    $('.overlay').toggleClass('active');
    $('body').toggleClass('open-menu');
  }

  ToggleNoCuenta(event: Event, elementAsk: HTMLAnchorElement): void {
    event.preventDefault();
    $('.box-no-cuenta .desplegable').slideToggle();
  }

  toggleMenu(event: Event, item: HTMLAnchorElement): void {
    event.preventDefault();
    $(item).toggleClass('active');
    $(item).siblings('.dropdown-menu').slideToggle();
  }

  toggleMenuInterno(event: Event): void {
    event.preventDefault();
    $('.item__mobile-menu').toggleClass('toggled');
  }

  /*addEventListeners(): void {
    const elements = document.querySelectorAll('.menu-toggle');

    elements.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        $(item).toggleClass('active');
        $(item).siblings('.dropdown-menu').slideToggle();
      })
    });

    const linksInternos = document.querySelectorAll('.dropdown-menu li>a');
    console.log(linksInternos)
    linksInternos.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        $(item).toggleClass('active');
        $('.item__mobile-menu').toggleClass('toggled');
      })
    });
  }*/

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.login()
    } else {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  private login() {
    this.error = false;
    this.apiPacienteService.login(this.form.value).subscribe({
      next: (r:any) => {
        (window as any).open('https://areapaciente.clinicabaviera.com/intro/login?userToken=' + r.data.UserToken);
      },
      error: err => {
        this.error = true;
        this.isNotValid('User');
      }
    })
  }

  public isNotValid(control: string): boolean {
    return (!this.form.controls[control].valid && this.submitted) || this.error;
  }
}
