import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {IImageComparisonItem} from "./interfaces/imageComparisonItem.interface";
import {toBoolean} from "../../../utils/functions";


@Component({
  selector: 'app-image-comparison',
  templateUrl: './image-comparison.component.html',
  styleUrls: ['./image-comparison.component.scss'],
})
export class ImageComparisonComponent {
  @Input() comparisonItem!: IImageComparisonItem
  _showLabel = false;

  @ViewChild('imageComparisonSlider') imageComparisonSlider!: ElementRef;
  @ViewChild('imageWrapperOverlay') imageWrapperOverlay!: ElementRef
  @ViewChild('comparisonSlider') comparisonSlider!: ElementRef;
  @ViewChild('sliderRange') sliderRange!: ElementRef;

  @Input() set showLabel(v: boolean | string) {
    this._showLabel = toBoolean(v);
  }

  get showLabel() {
    return this._showLabel;
  }

  moveSliderRange(e: Event | any) {

    const value = e.target.value;
    const slider = this.comparisonSlider.nativeElement
    const imageWrapperOverlay = this.imageWrapperOverlay.nativeElement

    slider.style.left = `${value}%`;
    imageWrapperOverlay.style.width = `${value}%`;

    this.setSliderState(e);
  }


  setSliderState(e: MouseEvent | Event) {
    const sliderRange = this.sliderRange.nativeElement

    if (e.type === 'input') {
      sliderRange.classList.add('image-comparison__range--active');
      return;
    }

    sliderRange.classList.remove('image-comparison__range--active');
  }


  onMouseUp($event: MouseEvent) {
    this.setSliderState($event);
  }

  onInput($event: Event) {
    this.moveSliderRange($event)
  }

  onChange($event: Event) {
    this.moveSliderRange($event)
  }


}
