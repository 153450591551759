import { Component } from '@angular/core';
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-banner-cataratas-primera-consulta',
  templateUrl: './banner-cataratas-primera-consulta.component.html',
  styleUrls: ['./banner-cataratas-primera-consulta.component.css']
})
export class BannerCataratasPrimeraConsultaComponent {
  public cdn: string;
  constructor() {
    this.cdn = environment.cdn;
   }
  toggleForm(event: Event): void {
    event.preventDefault();
    const itemSliderHomeForm = document.querySelector('.item__slider-home-form') as HTMLElement;
    const targetPosition = itemSliderHomeForm.getBoundingClientRect().top;
    const btnCita = document.querySelector('.btn-header-cita') as HTMLElement;
    const body = document.querySelector('body') as HTMLElement;

    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });

    itemSliderHomeForm.classList.toggle('opened');
    btnCita.classList.toggle('opened');

    const modalFilterForm = document.querySelector('.modal__filter-form') as HTMLElement;
    modalFilterForm.style.display = modalFilterForm.style.display == 'none' ? 'block' : 'none';
  }
}
