import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { ISolicitud } from "../interfaces/solicitud.interface";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SolicitudService {
  private readonly endpoint = 'solicitud';

  constructor(private http: HttpClient) {

  }

  public sendSolicitud(request: ISolicitud): Observable<any> {
    return this.http.post<any>(this.baseUrl, request);
  }

  public updateSolicitud(id: number, request: object): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}`, request);
  }

  private get baseUrl(): string {
    return environment.domain.concat(this.endpoint)
  }

}
