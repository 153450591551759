<form action="" class="item__filtro-medicos-form" [formGroup]="formSelector" (ngSubmit)="buscarMedicos()">
      <select name="unidad" class="item__especialidad" formControlName="id_unidad" (change)="seleccionaUnidad($event)">
        <option value="0">Selecciona especialidad</option>
        <option value="{{ unidad.id_unidad }}" *ngFor="let unidad of unidades">{{ unidad.nombre }}</option>
      </select>
      <select name="clinica" id="" class="item__clinicas" formControlName="id_clinica" (change)="seleccionaClinica($event)">
          <option value="0">Todas las clínicas</option>
          <option value="{{ clinica.id_clinica }}" *ngFor="let clinica of clinicas">{{ clinica.nombre }}</option>
      </select>
      <select name="medico" id="" formControlName="id_medico" class="item__nombre-doctor" (change)="seleccionaMedico($event)">
        <option value="0">Todos los médicos</option>
        <option value="{{ medico.id_medico }}" *ngFor="let medico of medicosUnidadClinica">{{ medico.tratamiento +' '+ medico.nombre +' '+ medico.apellidos }}</option>
      </select>
      <input type="submit" value="{{ personalizado ? 'Buscar' : 'Filtrar'}}" class="btn-{{ personalizado ? 'orange' : 'dark-blue'}} item__submit">
</form>
<!-- end item__form-filtro-medicos -->
<div class="item__filtro-medicos-resultado">
        <div class="item__filtro-medicos-ficha-medico" *ngFor="let medico of medicos">
            <!-- <img ngSrc="{{cdn}}imagenes/medicos/{{ medico.foto }}" alt="{{ medico.tratamiento +' '+ medico.nombre +' '+ medico.apellidos }}" width="330" height="390"> -->
            <picture>
              <source srcset="{{cdn}}imagenes/medicos/{{ medico.foto_webp }}.webp"  type="image/webp">
              <img ngSrc="{{cdn}}imagenes/medicos/{{ medico.foto }}" alt="{{ medico.tratamiento +' '+ medico.nombre +' '+ medico.apellidos }}" width="330" height="390" loading="lazy" type="image/jpeg">
            </picture>
            <a class="item__ficha-medico-text" href="clinica-oftalmologica-medico/{{ medico.nombre_seo }}">
                <h2 class="h2">{{ medico.tratamiento +' '+ medico.nombre +' '+ medico.apellidos }}</h2>
                <p class="info" [innerHTML]="nl2br(medico.descripcion_corta)"></p>
            </a>
        </div>
</div>
<!-- end item medicos resultados -->
<!-- <div class="item__load-more hidden-lg">
    <a href="#" class="load-more-doc">Ver más <img src="{{cdn}}imagenes/icons/icon-arrow-down-white-bg-circle-blue.svg" alt=""></a>
</div> -->

