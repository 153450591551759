import {Component, ElementRef, Input, ViewChild} from "@angular/core";
import * as $ from "jquery";
@Component({
  selector: 'app-collapse',
  standalone: true,
  templateUrl: './collapse.component.html',
})
export class CollapseComponent{
  @ViewChild('collapse') collapse!: ElementRef
  @ViewChild('toggle') toggle!: ElementRef
  @Input() showIcon: boolean = true
  @Input() header: string = ''
  public onClick($event: Event) {
    $event.preventDefault();
    const item = this.collapse.nativeElement
    const toggle = this.toggle.nativeElement
    $(item).toggleClass('active');
    $<any>(toggle).slideToggle();
  }
}