import { Component } from '@angular/core';
import { SLIDE_CONFIG } from 'src/app/core/utils/const/slideConfig.const';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-cataratas-opiniones',
  templateUrl: './cataratas-opiniones.component.html',
  styleUrls: ['./cataratas-opiniones.component.css']
})
export class CataratasOpinionesComponent {
  public readonly slideConfigB: object
  public cdn: string;

  constructor() {
   this.slideConfigB = SLIDE_CONFIG.operacionCataratasOpinionesComponent;   
   this.cdn = environment.cdn;
  }
}
