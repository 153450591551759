import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IClinic } from "src/app/core/interfaces/clinic.interface";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ClinicaService {
    private endpoint = 'clinicas/';
    private domain: string | undefined;

    constructor(private http: HttpClient){
        this.domain = environment.domain;
    }

    getClinicas(): Observable<IClinic[]> {
        return this.http.get<IClinic[]>(`${this.domain}${this.endpoint}`)
    }

    getClinicaPorCampo(campo:any, valor:any): Observable<IClinic[]> {
        return this.http.get<IClinic[]>(`${this.domain}${this.endpoint}/${campo}/${valor}`)
    }

    getClinicasPorUnidad(id: any): Observable<IClinic[]> {
        return this.http.get<IClinic[]>(`${this.domain}${this.endpoint}/unidad/${id}`)
    }

    getClinicaPorMedico(id: any): Observable<IClinic[]> {
        return this.http.get<IClinic[]>(`${this.domain}${this.endpoint}/medicoclinica/${id}`)
    }
}
